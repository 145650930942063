/* eslint-disable quotes,prettier/prettier */
import { locations, zones } from 'constants/onboarding/onboardingConstants';

export type TargetZonesType = keyof typeof zones;
export type WorkoutLocationsType = keyof typeof locations;

export enum ActivityLvlType {
    less_than_one_hour = 'less_than_one_hour',
    one_two_hours = 'one_two_hours',
    more_than_two_hours = 'more_than_two_hours',
}

export enum IConvertMeasureSystemValues {
    weight = 'weight',
    height = 'height',
    volume = 'volume',
}

export type SkipOnboarding = {
    reload: () => void;
};

export enum SedentaryLifeStyleType {
    yes = 'yes',
    no = 'no',
}

export enum MeasuringSystemType {
    METRIC = 'METRIC',
    IMPERIAL = 'IMPERIAL',
}

export enum GoalType {
    muscle_gain = 'muscle_gain',
    weight_loss = 'weight_loss',
}

export enum CurrentBodyType {
    average = 'average',
    skinny = 'skinny',
    overweight = 'overweight',
    slightly_overweight = 'slightly_overweight',
    extra = 'extra',
}

export enum ExerciseSatisfaction {
    like = 'like',
    neutral = 'neutral',
    dislike = 'dislike',
}

export enum TargetBodyType {
    cut = 'cut',
    bulk = 'bulk',
    extra_bulk = 'extra_bulk',
    fit = 'fit',
}

export enum FitnessLevelType {
    beginner = 'beginner',
    intermediate = 'intermediate',
    advanced = 'advanced',
}

export enum OccasionList {
    vacation = 'vacation',
    competition = 'competition',
    important_date = 'important_date',
    extreme_sports = 'extreme_sports',
    birthday = 'birthday',
    sporting_event = 'sporting_event',
    beach_trip = 'beach_trip',
    reunion = 'reunion',
    family_occasion = 'family_occasion',
    wedding = 'wedding',
    other = 'other',
    no = 'no',
}

export type IAgeGroup = '18_25' | '26_35' | '36_45' | '46_and_more';

export interface WithPreloadNextPageProps {
    getNextPage?: () => Promise<any>;
}

export enum EquipmentPresets {
    large_gym = 'large_gym',
    big_box_gym = 'big_box_gym',
    small_gym = 'small_gym',
    boutique_gym = 'boutique_gym',
    garage_gym = 'garage_gym',
    crossfit_gym = 'crossfit_gym',
    outdoor_fitness_park = 'outdoor_fitness_park',
    home_gym = 'home_gym',
}

export type TrainingDuration = '15_minutes' | '30_minutes' | '45_minutes' | '60_minutes';
