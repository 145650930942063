import { useTranslation } from 'react-i18next';
import React from 'react';

import config from 'config';

import { LONG_CHECKBOX_DISCLAIMER, SHORT_CHECKBOX_DISCLAIMER } from 'constants/payment/payments';
import { DEFAULT_COUNTRY } from 'constants/general/countriesData';

import ShortPolicyDisclaimer from 'components/ShortPolicyDisclaimer';
import LongPolicyDisclaimer from 'components/LongPolicyDisclaimer';

import { CheckoutConfigType } from 'interfaces/Payments/payments';

const useCheckoutConfig = (checkoutConfig?: CheckoutConfigType, country?: string | null) => {
    const { t } = useTranslation();
    const showPolicyConsent = country === DEFAULT_COUNTRY && checkoutConfig?.policy_consent_US !== 'off';

    const getPolicyText = () => {
        if (checkoutConfig?.policy_consent_US === LONG_CHECKBOX_DISCLAIMER)
            return <LongPolicyDisclaimer checkoutConfig={checkoutConfig} />;

        if (checkoutConfig?.policy_consent_US === SHORT_CHECKBOX_DISCLAIMER)
            return <ShortPolicyDisclaimer checkoutConfig={checkoutConfig} />;

        return null;
    };

    const mainTitlesKeys = {
        checkout: t('paymentFlow.checkoutGolf.title'),
        select: t('paymentFlow.checkoutFoxtrot.title'),
        results: t('Get visible results in 4 weeks'),
    };

    const secondTitleKeys = {
        payment: t('paymentFlow.checkoutFoxtrot.title'),
        results: t('Get visible results in 4 weeks'),
    };

    const defaultConfig = {
        mainTitle: mainTitlesKeys.checkout,
        secondTitle: secondTitleKeys.payment,
        paymentMethod: {
            payPall: true, // TODO: FIX TYPO IN JSON VALIDATION SCHEMA OF CONFIG
            googlePay: false, // The default value for all checkouts if the checkout page config is not exists
            applePay: true,
        },
    };

    const mainTitle = checkoutConfig?.mainTitle;
    const secondTitle = checkoutConfig?.secondTitle;
    const paymentMethod = { ...defaultConfig.paymentMethod, ...(checkoutConfig?.paymentMethod || {}) };

    const displayPaypalByConfig = config?.HIDE_PAYPAL !== '1';

    return {
        mainTitle: mainTitlesKeys[mainTitle as keyof typeof mainTitlesKeys] || defaultConfig.mainTitle,
        secondTitle: secondTitleKeys[secondTitle as keyof typeof secondTitleKeys] || defaultConfig.secondTitle,
        paymentMethod: {
            payPal: displayPaypalByConfig && paymentMethod.payPall, // TODO: FIX TYPO IN JSON VALIDATION SCHEMA OF CONFIG
            googlePay: false,
            applePay: paymentMethod.applePay,
        },
        policyConsent: showPolicyConsent && getPolicyText(),
        policyConsentType: checkoutConfig?.policy_consent_US,
    };
};

export default useCheckoutConfig;
