/* eslint-disable max-lines */
import { PAYMENT_TYPES_CAPITALIZED, SOLID_ERROR_CODE_NAMES } from 'constants/payment/payments';

import { Platform, PredefinedScenario } from './types';

export const SCENARIO_NAMES = {
    NEXT_ATTEMPT: 'NEXT_ATTEMPT',
    EXCLUSIVE_OFFER: 'EXCLUSIVE_OFFER',
};

export const LAST_SCENARIO_NUMBER = '4';

const androidScenarios: PredefinedScenario = {
    '1': {
        default: {
            name: SCENARIO_NAMES.NEXT_ATTEMPT,
            preselectedMethod: PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
            oneClickMethodsSequence: [PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY, PAYMENT_TYPES_CAPITALIZED.PAYPAL],
        },
    },
    '2': {
        default: {
            name: SCENARIO_NAMES.NEXT_ATTEMPT,
            preselectedMethod: PAYMENT_TYPES_CAPITALIZED.PAYPAL,
            oneClickMethodsSequence: [PAYMENT_TYPES_CAPITALIZED.PAYPAL, PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY],
        },
        errorsScenarios: {
            [SOLID_ERROR_CODE_NAMES.INSUFFICIENT_FUNDS]: {
                [SCENARIO_NAMES.EXCLUSIVE_OFFER]: {
                    name: SCENARIO_NAMES.EXCLUSIVE_OFFER,
                    preselectedMethod: PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                    oneClickMethodsSequence: [PAYMENT_TYPES_CAPITALIZED.PAYPAL, PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY],
                    options: {
                        availablePaymentSystemsForOffer: [PAYMENT_TYPES_CAPITALIZED.PAYPAL],
                    },
                },
            },
        },
    },
    '3': {
        default: {
            name: SCENARIO_NAMES.NEXT_ATTEMPT,
            preselectedMethod: PAYMENT_TYPES_CAPITALIZED.BANK_CARD,
            oneClickMethodsSequence: [PAYMENT_TYPES_CAPITALIZED.PAYPAL, PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY],
        },
    },
    [LAST_SCENARIO_NUMBER]: {
        // https://miro.com/app/board/uXjVN74G21o=/?moveToWidget=3458764575153588838&cot=14
        // here we don't have specific logic, so after 4 attempt, we leave 3rd case
        default: {
            name: SCENARIO_NAMES.EXCLUSIVE_OFFER,
            preselectedMethod: PAYMENT_TYPES_CAPITALIZED.BANK_CARD,
            oneClickMethodsSequence: [PAYMENT_TYPES_CAPITALIZED.PAYPAL, PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY],
        },
    },
};

const iOSScenarios: PredefinedScenario = {
    '1': {
        default: {
            name: SCENARIO_NAMES.NEXT_ATTEMPT,
            preselectedMethod: PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
            oneClickMethodsSequence: [
                PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
                PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
            ],
        },
    },
    '2': {
        default: {
            name: SCENARIO_NAMES.NEXT_ATTEMPT,
            preselectedMethod: PAYMENT_TYPES_CAPITALIZED.PAYPAL,
            oneClickMethodsSequence: [
                PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
                PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
            ],
        },
        errorsScenarios: {
            [SOLID_ERROR_CODE_NAMES.INSUFFICIENT_FUNDS]: {
                [SCENARIO_NAMES.EXCLUSIVE_OFFER]: {
                    // Scenario for exclusive offer
                    name: SCENARIO_NAMES.EXCLUSIVE_OFFER,
                    preselectedMethod: PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                    oneClickMethodsSequence: [
                        PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                        PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
                        PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
                    ],
                    options: {
                        availablePaymentSystemsForOffer: [PAYMENT_TYPES_CAPITALIZED.BANK_CARD],
                    },
                },
            },
        },
    },
    '3': {
        default: {
            name: SCENARIO_NAMES.NEXT_ATTEMPT,
            preselectedMethod: PAYMENT_TYPES_CAPITALIZED.BANK_CARD,
            oneClickMethodsSequence: [
                PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
                PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
            ],
        },
        errorsScenarios: {
            [SOLID_ERROR_CODE_NAMES.INSUFFICIENT_FUNDS]: {
                [SCENARIO_NAMES.EXCLUSIVE_OFFER]: {
                    name: SCENARIO_NAMES.EXCLUSIVE_OFFER,
                    preselectedMethod: PAYMENT_TYPES_CAPITALIZED.BANK_CARD,
                    oneClickMethodsSequence: [
                        PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                        PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
                        PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
                    ],
                    options: {
                        availablePaymentSystemsForOffer: [
                            // sequence does not matter
                            PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                            PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
                            PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
                            PAYMENT_TYPES_CAPITALIZED.BANK_CARD,
                        ],
                    },
                },
            },
        },
    },
    [LAST_SCENARIO_NUMBER]: {
        default: {
            name: SCENARIO_NAMES.NEXT_ATTEMPT,
            preselectedMethod: PAYMENT_TYPES_CAPITALIZED.BANK_CARD,
            oneClickMethodsSequence: [
                PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
                PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
            ],
        },
    },
};

const desktopScenarios: PredefinedScenario = {
    '1': {
        default: {
            name: SCENARIO_NAMES.NEXT_ATTEMPT,
            preselectedMethod: PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
            oneClickMethodsSequence: [
                PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
                PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
            ],
        },
    },
    '2': {
        default: {
            name: SCENARIO_NAMES.NEXT_ATTEMPT,
            preselectedMethod: PAYMENT_TYPES_CAPITALIZED.PAYPAL,
            oneClickMethodsSequence: [
                PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
                PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
            ],
        },
        errorsScenarios: {
            [SOLID_ERROR_CODE_NAMES.INSUFFICIENT_FUNDS]: {
                // https://miro.com/app/board/uXjVN74G21o=/?moveToWidget=3458764575885060467&cot=14
                [SCENARIO_NAMES.EXCLUSIVE_OFFER]: {
                    // Scenario for exclusive offer
                    name: SCENARIO_NAMES.EXCLUSIVE_OFFER,
                    preselectedMethod: PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                    oneClickMethodsSequence: [
                        PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                        PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
                        PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
                    ],
                    options: {
                        availablePaymentSystemsForOffer: [
                            // sequence does not matter
                            PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
                            PAYMENT_TYPES_CAPITALIZED.BANK_CARD,
                        ],
                    },
                },
            },
        },
    },
    '3': {
        default: {
            name: SCENARIO_NAMES.NEXT_ATTEMPT,
            preselectedMethod: PAYMENT_TYPES_CAPITALIZED.BANK_CARD,
            oneClickMethodsSequence: [
                // sequence not defined so doesn't matter because other method preselected
                PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
                PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
            ],
        },
        errorsScenarios: {
            [SOLID_ERROR_CODE_NAMES.INSUFFICIENT_FUNDS]: {
                [SCENARIO_NAMES.EXCLUSIVE_OFFER]: {
                    // Scenario for exclusive offer
                    name: SCENARIO_NAMES.EXCLUSIVE_OFFER,
                    preselectedMethod: PAYMENT_TYPES_CAPITALIZED.BANK_CARD,
                    oneClickMethodsSequence: [
                        PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                        PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
                        PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
                    ],
                    options: {
                        availablePaymentSystemsForOffer: [
                            // sequence does not matter
                            PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                            PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
                            PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
                            PAYMENT_TYPES_CAPITALIZED.BANK_CARD,
                        ],
                    },
                },
                [SCENARIO_NAMES.NEXT_ATTEMPT]: {
                    // Scenario for next attempt
                    name: SCENARIO_NAMES.NEXT_ATTEMPT,
                    preselectedMethod: PAYMENT_TYPES_CAPITALIZED.BANK_CARD,
                    oneClickMethodsSequence: [
                        PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                        PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
                        PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
                    ],
                },
            },
        },
    },
    [LAST_SCENARIO_NUMBER]: {
        default: {
            name: SCENARIO_NAMES.NEXT_ATTEMPT,
            preselectedMethod: PAYMENT_TYPES_CAPITALIZED.BANK_CARD,
            oneClickMethodsSequence: [
                // sequence not defined so doesn't matter because other method preselected
                PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
                PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
            ],
        },
    },
};

export const PREDEFINED_SCENARIOS: Record<Platform, PredefinedScenario> = {
    [Platform.ANDROID]: androidScenarios,
    [Platform.IOS]: iOSScenarios,
    [Platform.DESKTOP]: desktopScenarios,
};
