import { Text as LibText, IText } from 'wikr-core-components';
import React, { FC } from 'react';

const Text: FC<IText> = ({
    type,
    color,
    opacityValue,
    text,
    uppercase,
    bold,
    italic,
    inline,
    center,
    mb,
    ellipsis,
    children,
    className,
    as,
    dataLocator,
}) => {
    return (
        <LibText
            type={type}
            color={color}
            opacityValue={opacityValue}
            text={text}
            uppercase={uppercase}
            bold={bold}
            italic={italic}
            inline={inline}
            center={center}
            mb={mb}
            ellipsis={ellipsis}
            /* eslint-disable-next-line react/no-children-prop */
            children={children}
            className={className}
            as={as}
            dataLocator={dataLocator}
        />
    );
};

export { Text };
