/* eslint-disable max-lines */
import { SdkMessage } from '@solidgate/react-sdk';
import { MessageType } from '@solidgate/client-sdk-loader';

import {
    CLP,
    DAYS_PER_MONTH,
    DAYS_PER_WEEK,
    DEFAULT_TRIAL_PRICE,
    PAYMENT_METHOD_NAME,
    PAYMENT_TYPE_LIFETIME,
    PAYMENT_TYPE_SUBSCRIPTION,
    PRODUCT_CODES,
    SOLID_ERROR_CODE_MESSAGE_MAP,
    SOLID_ERROR_CODES,
    SOLID_ERROR_GROUPS_NAME,
} from 'constants/payment/payments';

import { replaceUnderscoreToDash } from 'helpers/other/transformationUtils';
import { getSubscriptionTitle } from 'helpers/normalizer/subscription';
import { dateNormalizer } from 'helpers/normalizer/normalizer';
import sendAnalyticsWithEventLabel from 'helpers/analytic/sendAnalyticsWithEventLabel';

import { CurrentProduct, ErrorMessage } from 'types/payments/payments';

import { DataForValidate } from 'interfaces/Payments/paymentSystemsInit';
import { currentProductType, pageInfo as pageInfoType } from 'interfaces/Payments/payments';

export const handleAnalyticData = (eventValue: string) => {
    sendAnalyticsWithEventLabel({
        url: window.location.pathname,
        eventValue,
    });
};

export const isLifetime = (paymentType: string) => {
    return paymentType === PAYMENT_TYPE_LIFETIME;
};

export const isSubscription = (paymentType: string) => {
    return paymentType === PAYMENT_TYPE_SUBSCRIPTION;
};

export const isVipSupport = (productCode: string) => {
    return productCode === PRODUCT_CODES.APP_VIP_SUPPORT;
};

export const fromPennyToInt = (penny: number) => {
    return penny / 100;
};

export const getPaymentNameByMethod = (paymentMethod: number) => {
    try {
        return PAYMENT_METHOD_NAME[paymentMethod];
    } catch (error) {
        throw new Error(error);
    }
};

export const getPriceFromCents = (price: number) => {
    return getToFixedNumber(Number(price) / 100);
};

export const getPriceFromCentsFixed = (price: number, digits = 2) => {
    return (Number(price) / 100).toFixed(digits);
};

export const getDiscount = (oldPrice: number, newPrice: number) => {
    return getToFixedNumber(((oldPrice - newPrice) / oldPrice) * 100, 0);
};

export const getPriceDiscount = (oldPrice: number, newPrice: number) => {
    return getToFixedNumber((newPrice / oldPrice - 1) * 100 * -1, 0);
};

export const getDiscountPercent = ({ startPrice, price }: { startPrice: number; price: number }) =>
    Math.round((1 - price / startPrice) * 100);

export const getDiscountForPayment = (currentProduct: CurrentProduct, pageInfo: pageInfoType) => {
    const selectedPeriod = currentProduct?.trial;
    const currentPrice = currentProduct?.price;
    const startPrice = currentProduct?.start_price_per_state;

    if (startPrice === 0) {
        return 0;
    }

    return getDynamicallyDiscount({ products: pageInfo?.products, selectedPeriod, currentPrice });
};

export const getDynamicallyDiscount = ({
    products,
    selectedPeriod,
    currentPrice,
}: {
    products: CurrentProduct[];
    selectedPeriod: number;
    currentPrice: number;
}) => {
    const productsByPeriod = products.filter((product) => product.trial === selectedPeriod);
    const maxElemFromArr = productsByPeriod.reduce((prev, cur) => (prev.price > cur.price ? prev : cur));
    const introductoryPriceMax = maxElemFromArr?.price;

    return Math.round((1 - currentPrice / introductoryPriceMax) * 100);
};

export const getProductPaymentData = (currentProduct: CurrentProduct) => {
    const { trial, period, start_price, price } = currentProduct;

    const currentPaymentType = replaceUnderscoreToDash(dateNormalizer(trial));
    const paymentType = trial === period ? 'SEVERAL_MONTH_PLAN' : 'ONE_WEEK_PLAN';
    const paymentLabel = getSubscriptionTitle(period);

    const fullPrice = getPriceFromCents(start_price);
    const trialPrice = getPriceFromCents(price);
    const trialLabel = trialNormalizer(trial);

    return { paymentType, currentPaymentType, paymentLabel, fullPrice, trialPrice, trialLabel };
};

export const trialNormalizer = (days: number) => {
    if (days === DAYS_PER_WEEK) {
        return '7-day Trial';
    }

    return '0_null'; // fix broken build
};

export const getToFixedNumber = (number: number, numbersAfterComma = 2) => {
    return Number(number.toFixed(numbersAfterComma));
};

export const getIndexOfPreselectedProduct = (products: CurrentProduct[]) => {
    const index = products.findIndex((item) => item.is_preselected);

    return index < 0 ? 1 : index;
};

export const filterProducts = <T extends { name: string }>(productName: string[], products: T[]) => {
    const res = productName.reduceRight((arr, el) => {
        const product = products.find((item) => item.name === el);

        return [...arr, product];
    }, []);

    return res as T[];
};

export const getMaxDiscount = (products: CurrentProduct[]) => {
    const discounts = [];

    for (let i = 0; i < products.length; i++) {
        discounts.push(getDiscount(products[i].start_price, products[i].price));
    }

    return discounts.reduce((p, v) => (p > v ? p : v));
};

export const getCheckoutDisclaimer = (
    isIntroDisclaimer: boolean,
    isTimerEnd: boolean,
    isTrialProduct: boolean,
    isCheckoutGolf: boolean
) => {
    switch (true) {
        case isTrialProduct:
            return 'paymentFlow.checkoutFoxtrot.trialDisclaimer';

        case !isCheckoutGolf && !isTrialProduct && !isTimerEnd && isIntroDisclaimer:
            return 'paymentFlow.checkoutFoxtrot1.disclaimer';

        case !isTrialProduct && isTimerEnd && !isIntroDisclaimer:
            return 'paymentFlow.checkoutFoxtrot.disclaimerAfterTimer';

        case isCheckoutGolf && isTrialProduct && !isTimerEnd:
            return 'paymentFlow.checkoutGolf.disclaimer';

        default:
            return 'paymentFlow.checkoutFoxtrot1.disclaimer';
    }
};

export const getTrialFullPrice = (products?: currentProductType[]) => {
    if (!products) return DEFAULT_TRIAL_PRICE;

    const oneMonthProduct = products.find(
        (product) => product.period === DAYS_PER_MONTH && product.trial === DAYS_PER_MONTH
    );

    return oneMonthProduct?.start_price ? getPriceFromCents(oneMonthProduct.start_price) : DEFAULT_TRIAL_PRICE;
};

export const getErrorReasonGroup = (errorCode: string) => {
    const foundReasonGroup = SOLID_ERROR_CODES.find(({ errorList }) => errorList.includes(errorCode));

    return foundReasonGroup ? foundReasonGroup.name : SOLID_ERROR_GROUPS_NAME.SOLID_NOT_STANDARD_ERROR;
};

export const isFailSolidError = (
    errorData: SdkMessage[MessageType.Error] | SdkMessage[MessageType.Fail]
): errorData is SdkMessage[MessageType.Fail] => {
    return (errorData as SdkMessage[MessageType.Fail]).order !== undefined;
};

export const getSolidErrorMessage = (message: ErrorMessage) => (typeof message === 'string' ? message : message[0]);

export const formatPriceRelativeToCurrency = (price: number, currency: string) => {
    if (currency.toUpperCase() === CLP.name) return price / 100;

    return price;
};

export const getPriceDigits = (price: number, isLongCurrency?: boolean) =>
    isLongCurrency && price % 100 === 0 ? 0 : 2;

export const preparePayPalErrorMessage = (error: DataForValidate['error']) => {
    if (!error) return new Error('Unknown PayPal Error!');

    const { recommended_message_for_user, code } = error;

    if (!code && !recommended_message_for_user) {
        return new Error(`PayPal error: ${error?.toString()}`);
    }

    let message = SOLID_ERROR_CODE_MESSAGE_MAP[code as keyof typeof SOLID_ERROR_CODE_MESSAGE_MAP] || code;

    if (recommended_message_for_user && recommended_message_for_user !== code) {
        message = recommended_message_for_user;
    }

    return new Error(message);
};
