import { useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';

import { selectCurrentProduct } from 'redux/Payment/selectors';

interface IUseSolidPaymentPreInit {
    initPayPal: () => void;
    initPaymentGenerator: () => void;
    paymentMethodsConfig: {
        payPal: boolean;
    };
}

export function useSolidPaymentPreInit({
    initPayPal,
    initPaymentGenerator,
    paymentMethodsConfig,
}: IUseSolidPaymentPreInit) {
    const currentProduct = useSelector(selectCurrentProduct);

    const displayPayPal = paymentMethodsConfig.payPal;

    const prevBankCardProductId = useRef<string | null | undefined>(null);
    const prevPayPalProductId = useRef<string | null | undefined>(null);

    const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        if (!currentProduct?.id) return;

        // PAY-PAL PRE-INIT
        const ppButton = document.getElementById('paypal-button');

        if (displayPayPal && ppButton) {
            const isTheSamePayPalProduct = prevPayPalProductId.current === currentProduct?.id;

            if (!isTheSamePayPalProduct) {
                if (timeoutId.current) {
                    clearTimeout(timeoutId.current);
                }

                // HACK TO FIX ASYNC ISSUE
                timeoutId.current = setTimeout(() => {
                    prevPayPalProductId.current = currentProduct?.id;
                    initPayPal();
                }, 100);
            }
        }

        // PRE-INIT BANK CARD
        const isTheSameProduct = currentProduct?.id === prevBankCardProductId.current;

        if (!isTheSameProduct) {
            initPaymentGenerator();
            prevBankCardProductId.current = currentProduct?.id;
        }

        return () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        };
    }, [currentProduct, prevBankCardProductId, prevPayPalProductId]);
}
