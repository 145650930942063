import { ISystemParams, systemNames } from 'wikr-core-analytics';

import config from 'config';

import { AnalyticSystems } from 'interfaces/Analytic/analytic';

interface ICookieAnalyticList {
    [cookieName: string]: ISystemParams[];
}

export const DEFAULT_ACTIVE_ANALYTICS: AnalyticSystems[] = [
    systemNames.pixel,
    systemNames.ga,
    systemNames.ga4,
    systemNames.amazon,
    systemNames.tiktok,
    systemNames.snapchat,
    systemNames.pinterest,
    systemNames.twitter,
];

export const STRICTLY_NECESSARY_COOKIE = 'C0001';
export const PERFORMANCE_COOKIE = 'C0002';
export const FUNCTIONAL_COOKIE = 'C0003';
export const TARGETING_COOKIE = 'C0004';
export const SOCIAL_MEDIA_COOKIE = 'C0005';

export const OTHER_ANALYTIC = [
    {
        name: systemNames.pixel,
        id: config.PIXEL_ID,
    },
    {
        name: systemNames.ga,
        id: config.GOOGLE_ID,
        config: {
            googleAdsId: config.GOOGLE_ADS_ID,
            googleAdsShopId: config.GOOGLE_ADS_SHOP_ID,
        },
    },
    {
        name: systemNames.ga4,
        id: config.GOOGLE_4_ID,
    },
    {
        name: systemNames.tiktok,
        id: config.TIKTOK_ID,
    },
    {
        name: systemNames.pinterest,
        id: config.PINTEREST_ID,
    },
    {
        name: systemNames.twitter,
        id: config.TWITTER_ID,
    },
    {
        name: systemNames.snapchat,
        id: config.SNAPCHAT_ID,
    },
];

export const COOKIE_ANALYTIC_LIST: ICookieAnalyticList = {
    [STRICTLY_NECESSARY_COOKIE]: [],
    [PERFORMANCE_COOKIE]: [],
    [FUNCTIONAL_COOKIE]: [],
    [TARGETING_COOKIE]: [...OTHER_ANALYTIC],
    [SOCIAL_MEDIA_COOKIE]: [],
};

export type ScreenType = SCREEN_TYPE;

export enum SCREEN_TYPE {
    mainPayment = 'main_payment',
    specialOffer = 'special_offer',
    upsell = 'upsell',
}
