import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { ILoaderProps } from 'types/pages/onboardingPages/componentsTypes';

import styles from './Loader.module.css';

const cx = classNames.bind(styles);

const Loader = ({ isLoading, additionalClass = '', isShadow = false }: ILoaderProps) => {
    const loaderStyles = cx('loader', {
        [additionalClass]: additionalClass,
    });

    // eslint-disable-next-line no-nested-ternary
    return isLoading && isShadow ? (
        <div className={styles.background} data-locator="spinnerLoader">
            <div className={loaderStyles} />
        </div>
    ) : isLoading ? (
        <div className={loaderStyles} data-locator="spinnerLoader" />
    ) : null;
};

export default memo(Loader);
