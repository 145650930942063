import { InitPayPal } from 'solid-payment/src/interfaces/payment';
import { ERROR_LEVELS, SENTRY_PAYMENT } from 'sentry-utils';
import { takeLatest, put } from 'redux-saga/effects';

import * as actionTypes from 'redux/Payment/actionTypes';
import { setIsLoadingPayPal, setPaymentData } from 'redux/Payment/actions';

import SolidPaymentService from 'services/SolidPayment';

import { PaymentData } from 'types/payments/paymentApiInterfaces';
import { ActionType } from 'types/commonInterfaces';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';

export function* init({ payload }: ActionType<InitPayPal>) {
    try {
        const response: PaymentData = yield SolidPaymentService.init('payPal', payload);

        yield put(setPaymentData(response));
    } catch (error) {
        yield put(setIsLoadingPayPal(false));
        sentryCaptureEvent(error, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, { ...payload });
    }
}

export const initPayPalSaga = function* () {
    yield takeLatest(actionTypes.INIT_PAY_PAL, init);
};
