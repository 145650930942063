import { uaParser } from 'wikr-core-analytics';
import {
    ANDROID,
    ANDROID_MIN_VERSION,
    CHROME,
    CHROME_MIN_VERSION,
    IOS,
    IOS_MIN_VERSION,
    SENTRY_AXIOS,
} from 'sentry-utils';
import { Event } from '@sentry/react';

import { CHUNK_LOAD_FAILED } from 'helpers/lazyWithRetry/constants';

import { isChunkError } from 'helpers/lazyWithRetry/lazyLoad';
import { isOnline } from 'helpers/isOnline';

import { errorType, Extras, IError, Tags } from './types';

export const checkIsOldDevices = (event: Event) => {
    try {
        const UAParser = new uaParser();
        const userAgent = UAParser.setUA(event?.extra?.agent as string);

        const browser = userAgent?.getBrowser();
        const deviceOS = userAgent?.getOS();

        const parsedOSVersion = parseFloat(deviceOS?.version || '');
        const parsedBrowserVersion = parseFloat(browser?.major || '');

        return (
            (deviceOS?.name === IOS && parsedOSVersion < IOS_MIN_VERSION) ||
            (deviceOS?.name === ANDROID && parsedOSVersion < ANDROID_MIN_VERSION) ||
            (browser?.name === CHROME && parsedBrowserVersion < CHROME_MIN_VERSION)
        );
    } catch (e) {
        console.info('no device info', e);
    }
};

export const getCustomTags = (error: IError, type: errorType, extras?: Extras): Tags => {
    const customTags: Record<string, string> = {};

    if (type === SENTRY_AXIOS) {
        customTags.API_ERROR_CODE = extras?.status as string;
    }

    return Object.entries(customTags);
};

export const setCustomTags = async (event: Event): Promise<Event> => {
    const customEvent: any = { ...event };

    if (customEvent) {
        customEvent.tags = { ...customEvent?.tags, IS_ONLINE: isOnline() };

        const { hasError } = isChunkError(customEvent);

        if (hasError) {
            customEvent.tags = { ...customEvent?.tags, ERROR_TYPE: CHUNK_LOAD_FAILED };
            console.error(CHUNK_LOAD_FAILED, customEvent);
        }
    }

    return customEvent;
};
