/* eslint-disable no-console */
import { takeLatest } from 'redux-saga/effects';

import api from 'api';

import * as actionTypes from 'redux/Payment/actionTypes';
import { setPaymentErrorCode } from 'redux/Payment/actions';

export function* sendPaymentFailToReteno({ payload }: ReturnType<typeof setPaymentErrorCode>) {
    // @ts-ignore
    try {
        if (payload) {
            yield api.payment.paymentFailToReteno(payload);
        }
    } catch ({ error }) {
        console.error('error', error);
    }
}

export const setPaymentErrorCodeToReteno = function* () {
    yield takeLatest(actionTypes.SET_PAYMENT_RETENO_ERROR, sendPaymentFailToReteno);
};
