/* eslint-disable max-lines */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import { selectUser } from 'redux/User/selectors';
import { selectIsApplePayAvailable, selectIsGooglePayAvailable } from 'redux/Payment/selectors';
import { changePaymentMethod } from 'redux/Payment/actions';
import { prevPaymentAttemptAccessor } from 'redux/Payment/accessors';

import { PAYMENT_TYPES_CAPITALIZED } from 'constants/payment/payments';

import useCheckoutConfig from 'hooks/useCheckoutConfig';

import { translate } from 'helpers/utils';
import { getPriceFromCentsFixed } from 'helpers/payment/paymentUtils';
import { GetScenarioParams, PaymentMethod } from 'helpers/payment/CheckoutConfigurator/types';
import {
    calculateScenarioNumber,
    getOnlyEnabledPaymentMethods,
} from 'helpers/payment/CheckoutConfigurator/helpers/helpers';
import { getScenario } from 'helpers/payment/CheckoutConfigurator/checkoutScenariosConfigurator';
import { formatLatamPrice } from 'helpers/other/transformationUtils';
import isMobile from 'helpers/other/isMobile';
import { getCurrentPlatform } from 'helpers/other/getPlatform';
import canPayWithApplePay from 'helpers/other/canPayWithApplePay';
import { dateNormalizerWithoutPlan } from 'helpers/normalizer/normalizer';
import sendAnalyticsWithEventLabel from 'helpers/analytic/sendAnalyticsWithEventLabel';

import { useDynamicCheckoutProps } from './types';

import { trackCheckoutClose } from 'analytics/trackers/payment';
import { useSolidPaymentLib } from './useSolidPaymentLib';
import { useCheckoutPolicyHandlers } from './useCheckoutPolicyHandlers';
import usePaymentContext from '../usePaymentContext';
import useCurrencyData from '../useCurrencyData';

export const useDynamicCheckout = ({ checkoutName }: useDynamicCheckoutProps) => {
    const dispatch = useDispatch();

    const { paymentModalCloseHandler, config, currentProduct, toNextPage } = usePaymentContext();
    const { currencyName, currencySign } = useCurrencyData();

    const isApplePayAvailable = useSelector(selectIsApplePayAvailable);
    const isGooglePayAvailable = useSelector(selectIsGooglePayAvailable);
    const { country } = useSelector(selectUser);
    const prevAttempt = prevPaymentAttemptAccessor();

    const checkoutConfig = useCheckoutConfig(config?.checkout, country);

    const platform = getCurrentPlatform();

    const scenarioConfig: GetScenarioParams = {
        platform,
        scenarioNumber: calculateScenarioNumber(prevAttempt),
        ...(prevAttempt && { previousAttempt: prevAttempt }),
    };

    const scenario = useMemo(() => getScenario(scenarioConfig), [prevAttempt?.scenarioNumber]);

    const { price, start_price, trial } = currentProduct;
    const isTrialProduct = trial === 7;
    const isRegularProduct = start_price === price;

    const fullPrice = getPriceFromCentsFixed(price);
    const plan = translate(dateNormalizerWithoutPlan(trial));
    const personalizeFullPrice = getPriceFromCentsFixed(start_price);
    const discountPrice = (Number(personalizeFullPrice) - Number(fullPrice)).toFixed(2);
    const discountPercent = Math.round((1 - Number(fullPrice) / Number(personalizeFullPrice)) * 100);

    const withPayPal = Boolean(checkoutConfig?.paymentMethod?.payPal);
    const withGooglePay = Boolean(checkoutConfig?.paymentMethod?.googlePay);
    const withApplePay = Boolean(checkoutConfig?.paymentMethod?.applePay);

    const enabledPaymentMethodsFromConfig: Record<string, boolean> = {
        payPal: withPayPal,
        googlePay: withGooglePay,
        applePay: withApplePay,
    };

    const displayApplePay = isApplePayAvailable && enabledPaymentMethodsFromConfig.applePay && canPayWithApplePay();
    const displayGooglePay = isGooglePayAvailable && enabledPaymentMethodsFromConfig.googlePay;
    const displayPayPal = enabledPaymentMethodsFromConfig.payPal;
    const isOneClick = displayPayPal || displayApplePay || displayGooglePay;

    const enabledPaymentMethods: PaymentMethod[] = useMemo(
        () =>
            getOnlyEnabledPaymentMethods(
                scenario,
                isApplePayAvailable,
                isGooglePayAvailable,
                enabledPaymentMethodsFromConfig
            ),
        [scenario, isApplePayAvailable, isGooglePayAvailable, enabledPaymentMethodsFromConfig]
    );

    const getActiveOption = () => {
        const { preselectedMethod } = scenario;

        if (
            enabledPaymentMethods.includes(preselectedMethod) ||
            preselectedMethod === PAYMENT_TYPES_CAPITALIZED.BANK_CARD
        ) {
            return preselectedMethod;
        }

        if (enabledPaymentMethods.length) {
            return enabledPaymentMethods[0];
        }

        return PAYMENT_TYPES_CAPITALIZED.BANK_CARD;
    };

    const [activeOption, setActiveOption] = useState(getActiveOption());

    const increaseModalHeight = isMobile.any() && activeOption === PAYMENT_TYPES_CAPITALIZED.BANK_CARD;

    useEffect(() => {
        dispatch(changePaymentMethod(activeOption));
    }, [activeOption]);

    useEffect(() => {
        handleChange(getActiveOption());
    }, [prevAttempt?.scenarioNumber, scenario?.preselectedMethod, isGooglePayAvailable, isApplePayAvailable]);

    const handleChange = (type: string) => {
        if (activeOption === type) return;

        setActiveOption(type);

        sendAnalyticsWithEventLabel({
            url: checkoutName,
            eventValue: type,
        });
    };

    const handleOnClose = () => {
        paymentModalCloseHandler?.();

        trackCheckoutClose(checkoutName);
    };

    const { onCheckboxClick, onOverlayClick } = useCheckoutPolicyHandlers();

    const {
        isLoading,
        paymentData,
        isProcessing,
        handleOnSubmit,
        handleOnSuccess,
        handleOnError,
        handleOnFail,
        handleOnReadyPaymentInstance,
        handleOnMounted,
        handleCard,
        hasPaymentError,
        hasPaymentSuccess,
        setHasPaymentError,
        setHasPaymentSuccess,
    } = useSolidPaymentLib({ toNextPage, activeOption });

    return {
        handleOnClose,
        handleChange,
        discountPercent,
        plan,
        isTrialProduct,
        isRegularProduct,
        activeOption,
        withPayPal,
        withGooglePay,
        checkoutConfig,
        withApplePay,
        increaseModalHeight,
        enabledPaymentMethodsFromConfig,
        scenario,
        discountPrice: formatLatamPrice(discountPrice),
        personalizeFullPrice: formatLatamPrice(personalizeFullPrice),
        fullPrice: formatLatamPrice(fullPrice),
        displayApplePay,
        displayGooglePay,
        displayPayPal,
        isOneClick,
        currencyName,
        currencySign,
        paymentData,
        isProcessing,
        handleOnSubmit,
        handleOnSuccess,
        handleOnError,
        handleOnFail,
        handleOnReadyPaymentInstance,
        handleOnMounted,
        handleCard,
        hasPaymentError,
        hasPaymentSuccess,
        setHasPaymentError,
        setHasPaymentSuccess,
        onCheckboxClick,
        onOverlayClick,
        isLoading,
        enabledPaymentMethods,
    };
};
