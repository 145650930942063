import { PAYMENT_TYPES_CAPITALIZED } from 'constants/payment/payments';

import { PaymentMethod, Platform } from '../types';

export const getDefaultOneClickMethodsSequence = (platform: string): PaymentMethod[] => {
    switch (platform) {
        case Platform.IOS:
            return [
                PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
                PAYMENT_TYPES_CAPITALIZED.PAYPAL,
                PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
            ];

        case Platform.ANDROID:

        case Platform.DESKTOP:
            return [PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY, PAYMENT_TYPES_CAPITALIZED.PAYPAL];

        default:
            return [PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY, PAYMENT_TYPES_CAPITALIZED.PAYPAL];
    }
};
