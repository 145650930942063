const getContentId = ({
    product_code,
    price,
    trialPrice,
    payment_type,
    trialPeriod,
    period,
}: {
    product_code: string;
    payment_type: string;
    price: number;
    trialPrice: number;
    trialPeriod: number;
    period: number;
}) => {
    const periodValue = `period: ${trialPeriod}/${period}`;

    const priceValue = `price: ${trialPrice}/${price}`;

    return `${payment_type}: ${product_code} | ${periodValue} | ${priceValue}`;
};

export default getContentId;
