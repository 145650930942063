/* eslint-disable max-lines */
import { defaultNavigatorLanguage } from 'constants/onboarding/defaultOnboardingData';
import { AVAILABLE_LOCALIZATIONS } from 'constants/general/availableLocalizations';

import { isEmpty } from 'helpers/other/dataStructuresUtils';

import { fromEntriesPolyfill } from './polyfills/fromEntriesPolyfill';
import { isFirstMirror } from './mirrorUtil';
import mirrorConfig from '../../mirrorConfig.json';

export const getCurrentDomainName = (): string => window.location.host;

export const getProjectEmail = (): string => {
    const domainName: string = getCurrentDomainName();

    return isFirstMirror() ? 'support@fitpoint.io' : `support@${domainName}`;
};

export const getCurrentLocalization = (): string => {
    const firstPathSegment = window.location.pathname.split('/')[1];

    return AVAILABLE_LOCALIZATIONS.includes(firstPathSegment) ? firstPathSegment : '';
};

export const getNavigatorLanguage = (): string => {
    let language;

    if (navigator?.languages?.length) {
        language = navigator.languages[0];
    } else {
        language = navigator?.language || defaultNavigatorLanguage;
    }

    return language?.split('-')[0];
};

export const getLocalizationFromAvailable = () => {
    const navigatorLanguage = getNavigatorLanguage();

    const isLanguageLocalized = AVAILABLE_LOCALIZATIONS.includes(navigatorLanguage);

    return isLanguageLocalized ? navigatorLanguage : '';
};

export const getParamFromUrl = (paramName: string): string | null => {
    const params = new URLSearchParams(window.location.search);

    return params.get(paramName);
};

export const getFaviconElement = (id = 'favicon') => {
    return document.getElementById(id);
};

export const getLogo = (): string => {
    const hostName: string = window.location.host;

    // @ts-ignore
    return mirrorConfig?.[hostName]?.logoUrl || '/logos/logo.png';
};

export const getUrlParamsFromURL = () => {
    const queryParams = window.location.search;

    if (!isEmpty(queryParams)) {
        const urlSearchParams = new URLSearchParams(queryParams)?.entries();

        if (typeof Object.fromEntries === 'function') {
            return Object.fromEntries(urlSearchParams);
        }

        return fromEntriesPolyfill(urlSearchParams);
    }

    return null;
};

export const deleteConfig = () => {
    localStorage.clear();
    localStorage.setItem('isResetStore', 'true');
};

const WIDTH_SIZE = 2000;
const HEIGHT_SIZE = 2000;

export const checkFbBots = () => {
    const windowWidth = window.screen.width;
    const windowHeight = window.screen.height;

    return windowWidth === WIDTH_SIZE && windowHeight === HEIGHT_SIZE;
};

// TODO investigate another function to use
export const scrollToPayment = (elementId: string) => {
    const element = document.getElementById(elementId);

    if (element) {
        const elementPosition = element.offsetTop - element.clientHeight / 2;

        const smoothScroll = (moveTo: number) => {
            // 100 - pixels to scroll. Small amount to prevent bug
            const pxAmount = 100;
            let step = moveTo;

            if (moveTo < elementPosition) {
                step = elementPosition - moveTo > pxAmount ? moveTo + pxAmount : elementPosition;

                setTimeout(() => {
                    smoothScroll(step);
                }, 20);
            }

            if (moveTo > elementPosition) {
                step = moveTo - elementPosition > pxAmount ? moveTo - pxAmount : elementPosition;

                setTimeout(() => {
                    smoothScroll(step);
                }, 20);
            }

            window.scrollTo({ top: moveTo });
        };

        smoothScroll(window.scrollY);
    }
};
