import { uaParser } from 'wikr-core-analytics';

import { DEVICE_OS } from 'constants/payment/payments';

import { DeviceOs } from 'interfaces/Payments/payments';

const UAParser = new uaParser();

export const getDeviceOS = (): DeviceOs => {
    const userAgent = window.navigator.userAgent;
    const getOSData = UAParser.setUA(userAgent).getOS();

    return (getOSData?.name?.toUpperCase() || DEVICE_OS.DEFAULT) as DeviceOs;
};
