import { PAYMENT_TYPES_CAPITALIZED, SOLID_ERROR_CODE_NAMES } from 'constants/payment/payments';
import { SCENARIO_NAMES } from './constants';

export enum Platform {
    ANDROID = 'ANDROID',
    IOS = 'IOS',
    DESKTOP = 'DESKTOP',
}

export type PaymentMethod = typeof PAYMENT_TYPES_CAPITALIZED[keyof typeof PAYMENT_TYPES_CAPITALIZED];

export type Scenario = {
    name: typeof SCENARIO_NAMES[keyof typeof SCENARIO_NAMES];
    preselectedMethod: PaymentMethod;
    oneClickMethodsSequence: PaymentMethod[];
    options?: {
        availablePaymentSystemsForOffer: PaymentMethod[];
    };
};

export type Attempt = {
    scenarioNumber: string;
    paymentMethod: PaymentMethod;
    response: {
        errorCode?: string;
    };
};

export type GetScenarioParams = {
    previousAttempt?: Attempt;
    platform: typeof Platform[keyof typeof Platform];
    scenarioNumber: string;
};

export type ErrorScenario = {
    [errorCode in typeof SOLID_ERROR_CODE_NAMES[keyof typeof SOLID_ERROR_CODE_NAMES]]?: {
        [scenarioName in typeof SCENARIO_NAMES[keyof typeof SCENARIO_NAMES]]?: Scenario;
    };
};

export type AttemptForScenario = {
    default: Scenario;
    errorsScenarios?: ErrorScenario;
};

export type PredefinedScenario = {
    [scenarioNumber: string]: AttemptForScenario;
};
