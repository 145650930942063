import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import React from 'react';

import config from 'config';

import Sentry from 'services/Sentry/SentryInstance';

import { configureStore } from './redux/configureStore';

import ErrorBoundary from './modules/ErrorHandler';
import App from './App';

import './styles.css';

Sentry.init();

const { store, persistor } = configureStore;

// AQA NEEDS
window.HIDE_PAYPAL = Number(config.HIDE_PAYPAL);
window.HIDE_FRONT_CHAT = Number(config.HIDE_FRONT_CHAT);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </PersistGate>
    </Provider>,
    document.getElementById('app')
);
