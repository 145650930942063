import { CardBrand } from '@solidgate/react-sdk';

import { ValidateData } from 'types/payments/paymentApiInterfaces';

import {
    CommonValidateData,
    DataAfterNormalization,
    payloadOrderInfo,
    ValidatePayloadAfterNormalization,
} from 'interfaces/Payments/payments';
import getAnalyticDataForBackend from './getAnalyticDataForBackend';

const getValidateData = (
    normalizedData: DataAfterNormalization,
    validatePayload: ValidatePayloadAfterNormalization,
    order: payloadOrderInfo,
    brand: CardBrand | '',
    paymentMethod: number
): ValidateData => {
    const { flow_link, ab_test_name, payment_type } = normalizedData;
    const isSubscription: boolean = payment_type === 'subscription';

    const analyticsForBackEnd = getAnalyticDataForBackend(normalizedData, validatePayload, order, brand);

    const commonValidateData: CommonValidateData = {
        order_id: order?.order_id,
        subscription_id: order?.subscription_id,
        payment_method: paymentMethod,
        payment_type,
        product_code: normalizedData.product_code,
        flow_link,
        ab_test_name,
        payment_screen: validatePayload.pageUrl,
        analytic_data: analyticsForBackEnd,
    };

    if (isSubscription) {
        return {
            ...commonValidateData,
            subscription_period: normalizedData?.subscription_period,
            subscription_trial_period: normalizedData?.subscription_trial_period,
        };
    }

    return commonValidateData;
};

export default getValidateData;
