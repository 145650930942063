import { Main } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';

import { SUBSCRIPTION_STATES } from 'constants/product/subscriptions';
import { PRODUCT_CODES } from 'constants/payment/payments';

import { trackGTM } from 'services/Analytics/trackers';

import sendAnalyticsWithEventLabel from 'helpers/analytic/sendAnalyticsWithEventLabel';

import { ISignInData } from 'types/user/auth';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';
import { UserSingUpInfo } from 'interfaces/User/user';
import { ISubscription } from 'interfaces/Subscriptions/subscription';

export function sendClickAnalytic(customURL: string | undefined) {
    sendAnalyticsWithEventLabel({
        url: customURL || window.location.pathname,
        eventValue: 'continue',
    });
}

export const handleRequiredFields = (
    actualInfo: UserSingUpInfo | ISignInData,
    defaultInfo: UserSingUpInfo | ISignInData
) => {
    const actualDataKeys = Object.keys(actualInfo);
    const defaultDataKeys = Object.keys(defaultInfo);

    const difference = defaultDataKeys.filter((x) => !actualDataKeys.includes(x));

    if (difference.length > 0) {
        sentryCaptureEvent(new Error('Wrong fields are send'), SENTRY_APP, ERROR_LEVELS.ERROR, {
            missingFields: difference,
        });
    }
};

export function sendSuccessfulSignUpAnalytic() {
    Main.track('Lead', { isPrefix: false });
    Main.track('Registration', { isPrefix: false });
    Main.track('SIGN_UP', { isPrefix: false });

    trackGTM({
        name: 'lead',
        data: { pageType: 'onboarding' },
    });
}

export const isMainSubscription = (productCode: ISubscription['product_code']) =>
    productCode === PRODUCT_CODES.APP_FULL_ACCESS || productCode === PRODUCT_CODES.APP_MAIN_PLAN;

export function findSubscriptions(data: ISubscription[]): {
    hasRedemptionSubscription: boolean;
    hasActiveSubscription: boolean;
} {
    return data.reduce(
        (acc, subscription) => {
            const isMainSub = isMainSubscription(subscription.product_code);

            if (isMainSub) {
                if (subscription.state === SUBSCRIPTION_STATES.REDEMPTION) {
                    return { ...acc, hasRedemptionSubscription: true };
                } else if (subscription.state === SUBSCRIPTION_STATES.ACTIVE) {
                    return { ...acc, hasActiveSubscription: true };
                }
            }

            return acc;
        },
        { hasRedemptionSubscription: false, hasActiveSubscription: false }
    );
}
