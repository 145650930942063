import { LAST_SCENARIO_NUMBER, PREDEFINED_SCENARIOS, SCENARIO_NAMES } from './constants';

import { getDefaultPaymentMethod } from './helpers/getDefaultPaymentMethod';
import { getDefaultOneClickMethodsSequence } from './helpers/getDefaultOneClickMethodsSequence';

import { Attempt, AttemptForScenario, GetScenarioParams, Platform, PredefinedScenario, Scenario } from './types';

import { androidStrategy, desktopStrategy, iOSStrategy } from './strategies';

const selectStrategy = (
    platform: Platform,
    predefinedScenarios: PredefinedScenario,
    previousAttempt?: Attempt
): ((scenarioNumber: string) => Scenario) => {
    const strategies: Record<
        Platform,
        (predefinedScenarios: PredefinedScenario, previousAttempt?: Attempt) => (scenarioNumber: string) => Scenario
    > = {
        [Platform.ANDROID]: androidStrategy,
        [Platform.IOS]: iOSStrategy,
        [Platform.DESKTOP]: desktopStrategy,
    };

    return strategies[platform](predefinedScenarios, previousAttempt);
};

const getScenario = ({ previousAttempt, platform, scenarioNumber }: GetScenarioParams): Scenario => {
    const predefinedScenarios: Record<string, AttemptForScenario> = PREDEFINED_SCENARIOS[platform];

    if (!predefinedScenarios) {
        return {
            name: SCENARIO_NAMES.NEXT_ATTEMPT,
            preselectedMethod: getDefaultPaymentMethod(platform),
            oneClickMethodsSequence: getDefaultOneClickMethodsSequence(platform),
        };
    }

    if (!predefinedScenarios[scenarioNumber]) {
        return predefinedScenarios[LAST_SCENARIO_NUMBER].default;
    }

    if (scenarioNumber === '1') {
        return predefinedScenarios['1'].default;
    }

    const strategy = selectStrategy(platform, predefinedScenarios, previousAttempt);

    const selectedScenario = strategy(scenarioNumber as string);

    if (!selectedScenario) {
        return {
            name: SCENARIO_NAMES.NEXT_ATTEMPT,
            preselectedMethod: getDefaultPaymentMethod(platform),
            oneClickMethodsSequence: getDefaultOneClickMethodsSequence(platform),
        };
    }

    return selectedScenario;
};

export { getScenario };
