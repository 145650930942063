import { ISystemParams } from 'wikr-core-analytics/dist/Interfaces/Systems';

import { COOKIE_ANALYTIC_LIST } from 'constants/analytic/analytic';

export const getAnalyticInitList = (cookieIdList: string[]): Array<ISystemParams> => {
    const analyticInitList: ISystemParams[] = [];

    cookieIdList.forEach((cookieId) => {
        const cookieAnalytics = COOKIE_ANALYTIC_LIST[cookieId];

        cookieAnalytics && analyticInitList.push(...cookieAnalytics);
    });

    return analyticInitList;
};

export const getAllAnalyticInitList = () =>
    Object.values(COOKIE_ANALYTIC_LIST).reduce((acc, analytic) => [...acc, ...analytic], []);
