import config from 'config';

import { deleteTokenOperation } from 'redux/User/operations';
import { getAuthTokenAccessor } from 'redux/User/accessors';

import { DEV_KEY } from '../constants/dev';

import { deleteConfig } from './other/commonUtils';

type moveToCabinetProps = {
    awsId?: string;
    uuid?: string;
};

export const moveToCabinet = ({ awsId, uuid }: moveToCabinetProps) => {
    const devKey = localStorage.getItem(DEV_KEY);

    const token = getAuthTokenAccessor();

    deleteTokenOperation();

    deleteConfig();

    const cabinetURL = new URL(config.CABINET_URL);

    cabinetURL.searchParams.append('ref', 'web2app');

    if (devKey) {
        cabinetURL.searchParams.append(DEV_KEY, devKey);
    }

    if (token) {
        cabinetURL.searchParams.append('token', token);
    }

    if (awsId) {
        cabinetURL.searchParams.append('track-id', awsId);
    }

    if (uuid) {
        cabinetURL.searchParams.append('uuid', uuid);
    }

    window.location.replace(cabinetURL.toString());
};
