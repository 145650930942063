/* eslint-disable max-lines */
export const PaymentPages = [
    {
        path: 'payment/VipSupport',
        route: 'vip-support',
    },
    {
        path: 'payment/Landings/PaymentG1Foxtrot1',
        route: 'payment-g1-foxtrot1',
    },
    {
        path: 'payment/Landings/OfferA1Foxtrot1',
        route: 'offer-a1-foxtrot1',
    },
    {
        path: 'payment/Landings/OfferE1Foxtrot2',
        route: 'offer-e1-foxtrot2',
    },
    {
        path: 'payment/Landings/OfferE1Foxtrot3',
        route: 'offer-e1-foxtrot3',
    },
    {
        path: 'payment/Landings/PaymentE1Foxtrot3',
        route: 'payment-e1-foxtrot3',
    },
    {
        path: 'payment/Landings/OfferE1Foxtrot4',
        route: 'offer-e1-foxtrot4',
    },
    {
        path: 'payment/Landings/OfferE1Foxtrot5',
        route: 'offer-e1-foxtrot5',
    },
    {
        path: 'payment/Landings/PaymentE1Foxtrot4',
        route: 'payment-e1-foxtrot4',
    },
    {
        path: 'payment/Landings/PaymentE1Foxtrot5',
        route: 'payment-e1-foxtrot5',
    },
    {
        path: 'payment/Landings/PaymentE2Foxtrot6',
        route: 'payment-e2-foxtrot6',
    },
    {
        path: 'payment/Landings/OfferE2Foxtrot6',
        route: 'offer-e2-foxtrot6',
    },
    {
        path: 'payment/Landings/PaymentE2Foxtrot18',
        route: 'payment-e2-foxtrot18',
    },
    {
        path: 'payment/Landings/OfferE2Foxtrot18',
        route: 'offer-e2-foxtrot18',
    },
    {
        path: 'payment/Landings/PaymentE3Foxtrot18',
        route: 'payment-e3-foxtrot18',
    },
    {
        path: 'payment/Landings/OfferE3Foxtrot18',
        route: 'offer-e3-foxtrot18',
    },
    {
        path: 'payment/Landings/PaymentE3Foxtrot6',
        route: 'payment-e3-foxtrot6',
    },
    {
        path: 'payment/Landings/OfferE3Foxtrot6',
        route: 'offer-e3-foxtrot6',
    },
    {
        path: 'payment/Landings/PaymentE5Foxtrot6',
        route: 'payment-e5-foxtrot6',
    },
    {
        path: 'payment/Landings/OfferE5Foxtrot6',
        route: 'offer-e5-foxtrot6',
    },
    {
        path: 'payment/Landings/PaymentE6Foxtrot6',
        route: 'payment-e6-foxtrot6',
    },
    {
        path: 'payment/Landings/OfferE6Foxtrot6',
        route: 'offer-e6-foxtrot6',
    },
    {
        path: 'payment/Landings/PaymentE5Golf3',
        route: 'payment-e5-golf3',
    },
    {
        path: 'payment/Landings/OfferE5Golf3',
        route: 'offer-e5-golf3',
    },
    {
        path: 'payment/Landings/PaymentE5Foxtrot23',
        route: 'payment-e5-foxtrot23',
    },
    {
        path: 'payment/Landings/OfferE5Foxtrot23',
        route: 'offer-e5-foxtrot23',
    },
    {
        path: 'payment/Landings/PaymentE7Foxtrot6',
        route: 'payment-e7-foxtrot6',
    },
    {
        path: 'payment/Landings/OfferE7Foxtrot6',
        route: 'offer-e7-foxtrot6',
    },
    {
        path: 'payment/Landings/PaymentE7Golf4',
        route: 'payment-e7-golf4',
    },
    {
        path: 'payment/Landings/OfferE7Golf4',
        route: 'offer-e7-golf4',
    },
    {
        path: 'payment/Landings/PaymentE8Golf5',
        route: 'payment-e8-golf5',
    },
    {
        path: 'payment/Landings/OfferE8Golf5',
        route: 'offer-e8-golf5',
    },
    {
        path: 'payment/Landings/PaymentE9Golf6',
        route: 'payment-e9-golf6',
    },
    {
        path: 'payment/Landings/OfferE9Golf6',
        route: 'offer-e9-golf6',
    },
    {
        path: 'payment/Landings/PaymentE10Golf7',
        route: 'payment-e10-golf7',
    },
    {
        path: 'payment/Landings/OfferE10Golf7',
        route: 'offer-e10-golf7',
    },
    {
        path: 'payment/Landings/PaymentE11Golf8',
        route: 'payment-e11-golf8',
    },
    {
        path: 'payment/Landings/OfferE11Golf8',
        route: 'offer-e11-golf8',
    },
    {
        path: 'payment/Landings/PaymentE11Golf9',
        route: 'payment-e11-golf9',
    },
    {
        path: 'payment/Landings/OfferE11Golf9',
        route: 'offer-e11-golf9',
    },
    {
        path: 'payment/Landings/PaymentE12Golf8',
        route: 'payment-e12-golf8',
    },
    {
        path: 'payment/Landings/OfferE12Golf8',
        route: 'offer-e12-golf8',
    },
    {
        path: 'payment/Landings/PaymentJ1Golf7',
        route: 'payment-j1-golf7',
    },
];
