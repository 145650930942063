import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { validatePayment } from 'redux/Payment/actions';

import { getArrayFromString } from 'helpers/other/transformationUtils';

import { IncomingPayloadForValidate, pageInfo } from 'interfaces/Payments/payments';

interface useValidatePaymentProps {
    pageInfo: pageInfo;
}

const useValidatePayment = ({ pageInfo }: useValidatePaymentProps) => {
    const dispatch = useDispatch();

    const history = useHistory();

    return (data: IncomingPayloadForValidate['data']) => {
        const payload = {
            data,
            screenId: history.location.pathname,
            analytics: getArrayFromString(pageInfo?.config?.analytics),
        };

        dispatch(validatePayment(payload));
    };
};

export default useValidatePayment;
