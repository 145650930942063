import { getLanguage } from 'helpers/utils';

export const getTermsOfUse = () => {
    const language = getLanguage();

    return `https://legal.muscle-booster.io/page/terms-of-use?language=${language}`;
};

export const getRefundPolicy = () => {
    const language = getLanguage();

    return `https://legal.muscle-booster.io/page/refund-policy?language=${language}`;
};

export const getPrivacyPolicy = () => {
    const language = getLanguage();

    return `https://legal.muscle-booster.io/page/privacy-policy?language=${language}`;
};
