import { Main } from 'wikr-core-analytics';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { getProjectEmail } from 'helpers/other/commonUtils';

import { Button as LibButton, Text, Image } from 'libComponents';
import Logo from 'components/Logo';
import Header from 'components/Header';
import Container from 'components/Container';
import Button from 'components/Button';

import styles from './ErrorPopUp.module.css';

import imgWebp from './img/img_error.webp';

function ErrorPopUp() {
    const { t } = useTranslation();

    const reloadClick = () => {
        Main.customData('onboarding_page_error__click', { event_label: 'reload' });
        window.location.reload();
    };

    const toSupportClick = () => {
        Main.customData('onboarding_page_error__click', { event_label: 'contact_us' });
        window.location.replace('mailto:' + getProjectEmail());
    };

    useEffect(() => {
        Main.customData('onboarding_page_error__load', { event_label: window.location.pathname });
    }, []);

    return (
        <>
            <Header left={<Logo />} />
            <Container withHeader>
                <div className={styles.imgWrapper}>
                    <Image src={imgWebp} alt="error" mb={16} />
                </div>
                <Text type="h3" center mb={8} text={t('other.errorPage.notFound')} color="on-primary" />
                <Text color="text-secondary-subdued" text={t('other.errorPage.unavailable')} center mb={32} />
                <div className={styles.buttonBox}>
                    <LibButton
                        text="other.errorPage.reload"
                        onClick={reloadClick}
                        mb={20}
                        className={styles.mainButton}
                    />
                    <Button title="Contact Us" onClick={toSupportClick} contactUsBtn />
                </div>
            </Container>
        </>
    );
}

export default ErrorPopUp;
