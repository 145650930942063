import { Main } from 'wikr-core-analytics';
import { useDispatch, useSelector } from 'react-redux';

import { selectPolicyInfo } from 'redux/UiEffects/selectors';
import { setPolicyAccepted, setPolicyShowError } from 'redux/UiEffects/actions';

export const useCheckoutPolicyHandlers = () => {
    const dispatch = useDispatch();
    const policyInfo = useSelector(selectPolicyInfo);

    const togglePolicyAccepted = () => {
        const newValue = policyInfo?.isPolicyAccepted ?? true;

        dispatch(setPolicyAccepted(!newValue));
        if (!newValue) {
            Main.customData('policy_consent__consent_provided__click');
            dispatch(setPolicyShowError(false));
        }
    };

    const onCheckboxClick = () => {
        togglePolicyAccepted();
    };

    const onOverlayClick = () => {
        if (!policyInfo?.isPolicyAccepted) {
            dispatch(setPolicyShowError(true));
        }
    };

    return {
        onCheckboxClick,
        onOverlayClick,
    };
};
