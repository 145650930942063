import { DeviceType, StringDictionary } from 'wikr-core-analytics/dist/types';
import { UserId } from 'wikr-core-analytics/dist/Interfaces/DataProvider';
import { Main } from 'wikr-core-analytics';

const getAmazonDefaultData = async (): Promise<{
    aws_id: string;
    event_type: string;
    host: string;
    url: string;
    screen_height: number;
    screen_width: number;
    lang: string;
    language: string;
    agent: string;
    device: string | undefined;
    referrer: string;
    timestamp: number;
    browser_info: object;
    device_type: DeviceType;
    uuid: string;
    user_id: UserId;
    url_params: StringDictionary;
    country: string;
    cookies: object;
    gender: string;
    ab_test_name: string;
    growthbook_ab_test_name: string;
    region: string | null;
} | null> => {
    const isInitAmazonAnalytic = Main.checkIsAlreadyInited({ name: 'amazon' });

    if (isInitAmazonAnalytic) {
        return await Main?.getDefaultAmazonAnalyticPurchaseData?.();
    }

    return null;
};

export default getAmazonDefaultData;
