import { useTranslation } from 'react-i18next';

export const translate = (key: string, options?: string) => {
    const { t } = useTranslation();

    return t(key, options);
};

export const getLanguage = () => localStorage.getItem('language') || 'en';

export const isLocalHost = () => window.location.hostname === 'localhost';

export const removeLocalhostFromObject = <T>(obj: T): T => {
    const jsonString = JSON.stringify(obj);

    const regex = /"([^"]*localhost[^"]*)"/g;

    const modifiedJsonString = jsonString.replace(regex, '"test"');

    return JSON.parse(modifiedJsonString) as T;
};
