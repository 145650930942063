import { Main } from 'wikr-core-analytics';

import { replaceDashToUnderscore } from 'helpers/other/transformationUtils';
import { getKgWeight } from 'helpers/onboarding/onboardingUtils';

interface CustomDataProps {
    url: string;
    eventValue?: any;
    eventAction?: string;
}

export default ({ url, eventValue: data, eventAction }: CustomDataProps) => {
    const decodedURL = decodeURI(url);
    // TODO need to refactor this logic
    const formattedUrl = decodedURL.split('/').pop();

    let eventValue = data;

    // for case when need to send unit values
    if (typeof data === 'object' && data.value) {
        eventValue = getKgWeight(data);
    }

    // @ts-ignore
    const eventName = `${replaceDashToUnderscore(formattedUrl)}__click`;

    Main.customData(eventName, { event_label: eventValue, event_action: eventAction });
};
