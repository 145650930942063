export const UpsellPages = [
    {
        path: 'payment/Upsells/Upsell2',
        route: 'upsell-2',
    },
    {
        path: 'payment/Upsells/UpsellSub',
        route: 'upsell-sub',
    },
    {
        path: 'payment/Upsells/UpsellSubA',
        route: 'upsell-sub-a',
    },
    {
        path: 'payment/Upsells/UpsellSubMeal',
        route: 'upsell-sub-meal',
    },
];
