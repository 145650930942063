import { Platform } from '../payment/CheckoutConfigurator/types';

import isMobile from './isMobile';

const IEMobile = () => {
    return navigator.userAgent.match(/IEMobile/i);
};

const anyMobile = () => {
    return Boolean(isMobile.android() || isMobile.blackBerry() || isMobile.iOS() || isMobile.opera() || IEMobile());
};

export const getCurrentPlatform = () => {
    if (Boolean(isMobile.android())) {
        return Platform.ANDROID;
    }
    if (Boolean(isMobile.iOS())) {
        return Platform.IOS;
    }

    if (!anyMobile()) {
        return Platform.DESKTOP;
    }

    return Platform.DESKTOP;
};
