import { configureStore } from '../configureStore';

export const getAuthTokenAccessor = () => {
    const { store } = configureStore;

    return store.getState()?.user.token;
};
export const userDataAccessor = () => {
    const { store } = configureStore;

    return store.getState()?.user;
};
