import { SOLID_ERROR_CODE_NAMES } from 'constants/payment/payments';

import { AttemptForScenario, PaymentMethod, Scenario } from '../types';

import { handleInsufficientFunds } from './insufficientFundsHandler';

export const errorHandlers: Record<
    string,
    (
        prevPaymentMethod: PaymentMethod,
        errorCode: keyof typeof SOLID_ERROR_CODE_NAMES,
        attemptScenarios: AttemptForScenario
    ) => Scenario | undefined
> = {
    [SOLID_ERROR_CODE_NAMES.INSUFFICIENT_FUNDS]: handleInsufficientFunds,
};
