import { SOLID_ERROR_CODE_NAMES } from 'constants/payment/payments';

import { createErrorStrategy } from '../helpers/createErrorStrategy';

import { Attempt, AttemptForScenario } from '../types';

export const iOSStrategy = (predefinedScenarios: Record<string, AttemptForScenario>, previousAttempt?: Attempt) => {
    const defaultStrategy = (scenarioNumber: string) => predefinedScenarios[scenarioNumber].default;

    if (previousAttempt && previousAttempt.response && previousAttempt.response.errorCode) {
        const {
            paymentMethod,
            response: { errorCode },
        } = previousAttempt;

        const strategies: Record<string, string[]> = {
            BANK_CARD: [SOLID_ERROR_CODE_NAMES.INSUFFICIENT_FUNDS],
            PAYPAL: [SOLID_ERROR_CODE_NAMES.INSUFFICIENT_FUNDS],
        };

        if (strategies[paymentMethod] && strategies[paymentMethod].includes(errorCode)) {
            return createErrorStrategy(predefinedScenarios, previousAttempt);
        }
    }

    return defaultStrategy;
};
