import { Trans } from 'react-i18next';
import React from 'react';

import { LITE_DISCLAIMER_COLOUR } from 'constants/payment/payments';
import { getTermsOfUse, getRefundPolicy } from 'constants/general/policy';

import { Text } from 'libComponents';

import styles from 'assets/styles/Common.module.css';

import { PolicyDisclaimerProps } from 'types/payments/payments';

function LongPolicyDisclaimer({ checkoutConfig }: PolicyDisclaimerProps) {
    return (
        <Text
            type="small-text"
            color={
                checkoutConfig?.policy_consent_US_colour === LITE_DISCLAIMER_COLOUR
                    ? 'text-secondary-subdued'
                    : 'surface-default'
            }
        >
            <Trans
                i18nKey="onboardingFlow.emailConsentConfA.disclaimer"
                components={{
                    TermsOfUse: (
                        <a
                            className={styles.underline}
                            href={getTermsOfUse()}
                            rel="noopener noreferrer"
                            target="_blank"
                            data-locator="termsOfUseLink"
                        >
                            Terms of Use
                        </a>
                    ),
                    RefundPolicy: (
                        <a
                            className={styles.underline}
                            href={getRefundPolicy()}
                            rel="noopener noreferrer"
                            target="_blank"
                            data-locator="refundPolicyLink"
                        >
                            Refund Policy
                        </a>
                    ),
                }}
            />
        </Text>
    );
}

export default LongPolicyDisclaimer;
