export enum FAILED_VALIDATION_HINT {
    age = 'Enter a value from 16 to 99',
    currentWeight = 'Enter a value from 30 to 350 kg or from 66 to 770 lbs',
    weightMetric = 'Enter a value from 30 to 350 kg',
    weightImperial = 'Enter a value from 66 to 770 lbs',
    heightMetric = 'Enter a value from 40 to 250 cm',
    heightInches = 'Enter a value from 4ft to 7ft 11in',
    name = 'Name must contain only letters, spaces, hyphens',
    email = 'Email is invalid',
    integer = 'The value must be integer',
    fullName = 'validation.fullname.error',
}

export enum SUCCESSFUL_VALIDATION_HINT {
    age = 'Perfect',
    currentWeight = 'Perfect',
    name = 'Perfect',
    last_name = 'Perfect',
    email = 'Perfect',
    password = 'Perfect',
    newPassword = 'Perfect',
    confirmNewPassword = 'Perfect',
    phone = 'Perfect',
    fullName = 'Perfect',
}

export enum EMPTY_FIELD_VALIDATION_HINT {
    age = 'Enter a value from 16 to 99',
    currentWeight = 'Enter a value from 30 to 350 kg or from 66 to 770 lbs',
    name = 'Required!',
    last_name = 'Required!',
    email = 'Email is required',
    feedback = 'At least 20 characters',
    fullName = 'Required!',
}

export const FAILED_LENGTH_VALIDATION_HINT = {
    name: {
        min: 'Minimum 2 characters',
        max: 'Maximum 25 characters',
    },
    fullName: {
        min: 'Minimum 2 characters',
        max: 'Maximum 25 characters',
    },
    feedback: {
        min: 'At least 20 characters',
        max: 'No more than 200 characters',
    },
};
