import { SIGN_UP_URL, USER_URL } from '../constants/urls';

import ApiClient from 'services/ApiClient';

import { HashedEmailResponse, SignUpData, UserInfoResponse, UserUpdateData } from 'types/user/userApiInterface';

import { ISubscriptionResponse } from 'interfaces/Subscriptions/subscription';

class UserApi {
    getSubscriptions = async <Response = ISubscriptionResponse>(): Promise<Partial<Response> | unknown> => {
        return await ApiClient.get<Response>('user/subscriptions');
    };

    getUser = async <Response = UserInfoResponse>(): Promise<Partial<Response>> => {
        return await ApiClient.get<Response>(USER_URL);
    };

    update = async <Data = UserUpdateData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response>> => {
        return await ApiClient.patch<Data, Response>(USER_URL, payload);
    };

    signUp = async <Data = SignUpData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>(SIGN_UP_URL, payload);
    };

    hashedEmail = async (): Promise<HashedEmailResponse> => {
        return await ApiClient.get<HashedEmailResponse>('front-hash');
    };
}

export default UserApi;
