import { CLP } from 'constants/payment/payments';

import { getPaymentNameByMethod, getPriceFromCents } from 'helpers/payment/paymentUtils';
import { deleteSlash } from 'helpers/other/transformationUtils';

import {
    PaymentMethod,
    ValidatePayloadAfterNormalization,
    ValidatePayloadBeforeNormalization,
} from 'interfaces/Payments/payments';

const normalizeValidatePayload = ({
    amount,
    screenId,
    paymentMethod,
    currency,
}: ValidatePayloadBeforeNormalization): ValidatePayloadAfterNormalization => {
    const amountValue: number = currency === CLP.name ? amount : getPriceFromCents(amount);
    const pageUrl: string = deleteSlash(screenId);
    const paymentMethodName = getPaymentNameByMethod(paymentMethod as number) as PaymentMethod;

    return {
        amountValue,
        pageUrl,
        paymentMethodName,
    };
};

export default normalizeValidatePayload;
