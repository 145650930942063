import { Main, Provider } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { all, put, takeLatest } from 'redux-saga/effects';

import api from 'api';

import { setTokenOperation } from 'redux/User/operations';
import {
    setIsAutologinComplete,
    setIsLogin,
    setLanguage,
    setPaidStatus,
    setUserEmail,
    setUserId,
    setUserProducts,
} from 'redux/User/actions';
import { INIT_AUTO_LOGIN } from 'redux/Onboadring/actionTypes';
import {
    setAge,
    setCurrentBody,
    setCurrentWeight,
    setFitnessLvl,
    setGoal,
    setMeasure,
    setTall,
    setTargetBody,
    setTargetWeight,
} from 'redux/Onboadring/actions';

import {
    defaultCurrentBody,
    defaultCurrentWeight,
    defaultDateOfBirth,
    defaultFitnessLevel,
    defaultGoal,
    defaultLanguage,
    defaultMeasureSystem,
    defaultTall,
    defaultTargetBody,
    defaultTargetWeight,
} from 'constants/onboarding/defaultOnboardingData';

import sentry from 'services/Sentry/SentryInstance';

import { moveToCabinet } from 'helpers/redirects';
import { getCurrentLocalization, getLocalizationFromAvailable, getParamFromUrl } from 'helpers/other/commonUtils';
import { getAgeFromBirthday } from 'helpers/onboarding/onboardingUtils';

import { ISignInData } from 'types/user/auth';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';
import { ISingInResponse } from 'interfaces/User/user';
import { ISubscriptionResponse } from 'interfaces/Subscriptions/subscription';
import { findSubscriptions } from './helper';

export function* autoLoginSaga() {
    const token = getParamFromUrl('token');

    if (!token) return;

    try {
        const language = getLocalizationFromAvailable();
        const languageFromURL = getCurrentLocalization();
        const currentLanguage = language || languageFromURL || defaultLanguage;

        yield put(setLanguage(currentLanguage));

        setTokenOperation(token);

        const { aws_id: awsId } = yield Main.getDefaultAmazonAnalyticPurchaseData();

        const response: ISingInResponse = yield api.user.getUser();

        const userData: ISignInData = response.data;

        const measureSystem = userData?.units?.toUpperCase?.() || defaultMeasureSystem;

        const {
            email,
            id,
            is_password,
            goal,
            height,
            weight,
            target_weight,
            fitness_level,
            current_bodytype,
            target_bodytype,
            date_of_birth,
            product_list,
        } = userData;

        let isPaidUser = Boolean(product_list?.main_plan || product_list?.app_full_access);
        const shouldSkipThankYouPage = userData.name && userData.surname;

        sentry.setUser({ email, user_id: id });

        if (isPaidUser && shouldSkipThankYouPage) {
            const response: ISubscriptionResponse = yield api.user.getSubscriptions();

            const { hasRedemptionSubscription, hasActiveSubscription } = findSubscriptions(response.data);

            if (hasRedemptionSubscription && !hasActiveSubscription) {
                isPaidUser = false;
            } else {
                moveToCabinet({ awsId });

                return;
            }
        }

        Provider.setUserId(id).then();
        Provider.setUserEmail(email);

        yield all([
            put(setUserEmail(email)),
            put(setUserId(id)),
            put(setPaidStatus(isPaidUser)),
            put(setGoal(goal || defaultGoal)),
            put(setMeasure(measureSystem)),
            put(setUserProducts(product_list)),
            put(
                setTall({
                    unit: measureSystem,
                    value: height || defaultTall,
                })
            ),
            put(
                setCurrentWeight({
                    unit: measureSystem,
                    value: weight || defaultCurrentWeight,
                })
            ),
            put(
                setTargetWeight({
                    unit: measureSystem,
                    value: target_weight || defaultTargetWeight,
                })
            ),
            put(setFitnessLvl(fitness_level || defaultFitnessLevel)),
            put(setCurrentBody(current_bodytype || defaultCurrentBody)),
            put(setTargetBody(target_bodytype || defaultTargetBody)),
            put(setAge(getAgeFromBirthday(date_of_birth || defaultDateOfBirth))),
            is_password && put(setIsLogin(is_password)),
            put(setIsAutologinComplete(true)),
        ]);
    } catch (error) {
        yield put(setIsAutologinComplete(true));

        sentryCaptureEvent(error, SENTRY_APP, ERROR_LEVELS.WARNING);
    }
}

export const setUserProfile = function* () {
    yield takeLatest(INIT_AUTO_LOGIN, autoLoginSaga);
};
