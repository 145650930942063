import { SOLID_ERROR_CODE_NAMES } from 'constants/payment/payments';
import { SCENARIO_NAMES } from '../constants';

import { AttemptForScenario, PaymentMethod, Scenario } from '../types';

export const handleInsufficientFunds = (
    prevPaymentMethod: PaymentMethod,
    errorCode: keyof typeof SOLID_ERROR_CODE_NAMES,
    attemptScenarios: AttemptForScenario
): Scenario | undefined => {
    const errorScenarios = attemptScenarios.errorsScenarios;

    if (!errorScenarios || !errorScenarios[errorCode]) {
        return undefined;
    }

    const paymentMethodsWithSpecificOffers =
        errorScenarios &&
        errorScenarios[errorCode] &&
        errorScenarios[errorCode]?.[SCENARIO_NAMES.EXCLUSIVE_OFFER]?.options?.availablePaymentSystemsForOffer;

    if (paymentMethodsWithSpecificOffers?.includes(prevPaymentMethod)) {
        return errorScenarios[errorCode]?.[SCENARIO_NAMES.EXCLUSIVE_OFFER];
    }

    return undefined;
};
