import { createErrorStrategy } from '../helpers/createErrorStrategy';

import { Attempt, AttemptForScenario } from '../types';

export const desktopStrategy = (predefinedScenarios: Record<string, AttemptForScenario>, previousAttempt?: Attempt) => {
    const defaultStrategy = (scenarioNumber: string) => predefinedScenarios[scenarioNumber].default;

    if (previousAttempt && previousAttempt.response && previousAttempt.response.errorCode) {
        return createErrorStrategy(predefinedScenarios, previousAttempt);
    }

    return defaultStrategy;
};
