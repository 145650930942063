import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { SeverityLevel } from '@sentry/react';

import { selectLanguage } from 'redux/User/selectors';
import { selectCurrentBranchName, selectTestaniaName } from 'redux/Testania/selectors';
import { RootState } from 'redux/rootReducer';

import sentry from 'services/Sentry/SentryInstance';
import { errorType, Extras } from 'services/Sentry/Sentry';

import { Props, State } from './interface';
import { ErrorFallback } from './ErrorFallback';

const sentryCaptureEvent = (error: Error, type: errorType, level: SeverityLevel, extra?: Extras): void => {
    // @ts-ignore
    sentry.logError(error, type, level, extra);
};

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        error: null,
        hasError: false,
    };

    componentDidCatch(error: Error) {
        const { pageLocale, testaniaName, currentBranchName } = this.props;

        this.setState({ error });

        sentryCaptureEvent(error, SENTRY_APP, ERROR_LEVELS.CRITICAL, { pageLocale, testaniaName, currentBranchName });
    }

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <ErrorFallback />;
        }

        return this.props.children;
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        testaniaName: selectTestaniaName(state),
        currentBranchName: selectCurrentBranchName(state),
        pageLocale: selectLanguage(state),
    };
};

export { sentryCaptureEvent };

export default connect(mapStateToProps)(ErrorBoundary);
