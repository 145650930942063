import config from 'config';

import * as action from './actions';

import * as actionTypes from './actionTypes';

import { getLogo } from 'helpers/other/commonUtils';

import { InferValueTypes } from 'types/commonInterfaces';

import { UIEffectsState } from 'interfaces/UiEffects/uieffects';

const logoURL = getLogo();

const initialState: UIEffectsState = {
    hide_header: null,
    isErrorModalOpen: false,
    deviceOSView: 'IOS',
    showAppLoader: true,
    toastMessages: {
        type: 'success',
        message: 'Changes successfully applied',
        customContent: null,
        delay: 4000,
        status: false,
    },
    initFrontChat: null,
    logoUrl: logoURL,
    inlineTheme: {},
    mirrorConfig: {
        firstPageImage: null,
        firstPageTitle: null,
    },
    redirectPath: '',
    hidePayPal: !!JSON.parse(config?.HIDE_PAYPAL || 'false'),
    policyInfo: {
        isPolicyAccepted: false,
        showPolicyError: false,
    },
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const UiEffects = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.HIDE_HEADER:
            return { ...state, hide_header: action.payload };

        case actionTypes.SHOW_MODAL:
            return { ...state, isErrorModalOpen: action.payload };

        case actionTypes.DEVICE_OS_VIEW:
            localStorage.setItem('deviceOS', action.payload);

            return { ...state, deviceOSView: action.payload };

        case actionTypes.SHOW_APP_LOADER:
            return { ...state, showAppLoader: action.payload };

        case actionTypes.SET_TOAST_CONFIG:
            return { ...state, toastMessages: action.payload };

        case actionTypes.INIT_FRONT_CHAT:
            return { ...state, initFrontChat: action.payload };

        case actionTypes.SET_MIRROR_CONFIG:
            return { ...state, mirrorConfig: Object.assign({}, state.mirrorConfig, action.payload) };

        case actionTypes.SET_LOGO_URL:
            return { ...state, logoUrl: action.payload };

        case actionTypes.SET_INLINE_THEME:
            return { ...state, inlineTheme: action.payload };

        case actionTypes.REDIRECT_TO:
            return { ...state, redirectPath: action.payload };

        case actionTypes.POLICY_ACCEPTED:
            return {
                ...state,
                policyInfo: { ...state.policyInfo, isPolicyAccepted: action.payload },
            };

        case actionTypes.POLICY_SHOW_ERROR:
            return {
                ...state,
                policyInfo: { ...state.policyInfo, showPolicyError: action.payload },
            };

        default:
            return state;
    }
};

export default UiEffects;
