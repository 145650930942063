import { createSelector } from 'reselect';

import { UIEffectsState } from 'interfaces/UiEffects/uieffects';
import { RootState } from '../rootReducer';

const selectUIEffects = (state: RootState) => state.ui_effects;
const selectIsErrorModalOpen = createSelector(
    selectUIEffects,
    (ui_effects: UIEffectsState) => ui_effects.isErrorModalOpen
);
const selectHideHeader = createSelector(selectUIEffects, (ui_effects: UIEffectsState) => ui_effects.hide_header);
const selectDeviceOSView = createSelector(selectUIEffects, (ui_effects: UIEffectsState) => ui_effects.deviceOSView);
const selectShowAppLoader = createSelector(selectUIEffects, (ui_effects: UIEffectsState) => ui_effects.showAppLoader);
const selectToastMessages = createSelector(selectUIEffects, (ui_effects: UIEffectsState) => ui_effects.toastMessages);
const selectInitFrontChat = createSelector(selectUIEffects, (ui_effects: UIEffectsState) => ui_effects.initFrontChat);
const selectInlineTheme = createSelector(selectUIEffects, (ui_effects: UIEffectsState) => ui_effects.inlineTheme);
const selectLogoUrl = createSelector(selectUIEffects, (ui_effects: UIEffectsState) => ui_effects.logoUrl);
const selectMirrorConfig = createSelector(selectUIEffects, (ui_effects: UIEffectsState) => ui_effects.mirrorConfig);
const selectRedirectPath = createSelector(selectUIEffects, (ui_effects: UIEffectsState) => ui_effects.redirectPath);
const selectHidePayPal = createSelector(selectUIEffects, (uiEffects) => uiEffects.hidePayPal);
const selectPolicyInfo = createSelector(selectUIEffects, (uiEffects) => uiEffects.policyInfo);

export {
    selectUIEffects,
    selectIsErrorModalOpen,
    selectHideHeader,
    selectDeviceOSView,
    selectShowAppLoader,
    selectToastMessages,
    selectInitFrontChat,
    selectInlineTheme,
    selectLogoUrl,
    selectMirrorConfig,
    selectRedirectPath,
    selectHidePayPal,
    selectPolicyInfo,
};
