import { SdkMessage } from '@solidgate/react-sdk';
import { MessageType } from '@solidgate/client-sdk-loader';

import { SOLID_TAGS } from 'constants/payment/payments';

import { Tags } from 'services/Sentry/types';

import { getErrorReasonGroup, getSolidErrorMessage, isFailSolidError } from './paymentUtils';

const getErrorCustomTags = ({
    errorData,
    country,
    paymentMethod,
}: {
    errorData: SdkMessage[MessageType.Error] | SdkMessage[MessageType.Fail];
    country: string;
    paymentMethod: string;
}) => {
    const error = errorData?.type === MessageType.Error ? errorData?.details : errorData;

    const errorCode = error?.code;
    const errorMessage = error?.message;
    const orderId = isFailSolidError(errorData) ? errorData.order?.order_id : '';

    const customTags: Tags = [
        ['country', String(country)],
        [SOLID_TAGS.SOLID_PAYMENT_METHOD, paymentMethod],
    ];

    if (errorCode) {
        customTags.push(
            [SOLID_TAGS.SOLID_ERROR_CODE, errorCode],
            [SOLID_TAGS.SOLID_ERROR_REASON, getErrorReasonGroup(errorCode)]
        );
    }

    if (orderId) {
        customTags.push([SOLID_TAGS.SOLID_ORDER_ID, orderId]);
    }

    if (errorMessage) {
        customTags.push([SOLID_TAGS.SOLID_ERROR_MESSAGE, getSolidErrorMessage(errorMessage)]);
    }

    return customTags;
};

export default getErrorCustomTags;
