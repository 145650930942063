import { CardBrand } from '@solidgate/react-sdk';

import {
    AnalyticDataForBackend,
    DataAfterNormalization,
    payloadOrderInfo,
    ValidatePayloadAfterNormalization,
} from 'interfaces/Payments/payments';
import getContentId from './getContentId';

const getAnalyticDataForBackend = (
    normalizedData: DataAfterNormalization,
    validatePayload: ValidatePayloadAfterNormalization,
    order: payloadOrderInfo,
    brand: CardBrand | ''
) => {
    const { currency, value, subscription_price, tariff, user_id, ab_test_name, payment_type, payment_method_name } =
        normalizedData;

    const contentId = getContentId({
        product_code: normalizedData.product_code,
        payment_type: normalizedData.payment_type,
        price: normalizedData.subscription_price,
        period: normalizedData.subscription_period,
        trialPeriod: normalizedData.subscription_trial_period,
        trialPrice: normalizedData.introductorySubscriptionPrice,
    });

    const analyticDataForBackend: AnalyticDataForBackend = {
        currency,
        value,
        subscription_price,
        user_id,
        tariff,
        ab_test_name,
        order_type: payment_type,
        payment: payment_method_name,

        content_id: contentId,
        price: validatePayload.amountValue,
        screen_id: validatePayload.pageUrl,

        subscription_id: order.subscription_id,
        order_id: order.order_id,

        card_type: brand,
    };

    return { ...normalizedData.defaultAmazonPurchaseData, ...analyticDataForBackend };
};

export default getAnalyticDataForBackend;
