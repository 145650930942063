/* eslint-disable max-lines */
export const OnboardingPages = [
    {
        path: 'onboarding/AgeA',
        route: 'age-a',
    },
    {
        path: 'onboarding/CurrentBody',
        route: 'current-body-type',
    },
    {
        path: 'onboarding/CurrentWeightA',
        route: 'weight-a',
    },
    {
        path: 'onboarding/EmailConsentPageA',
        route: 'email-consent-a',
    },
    {
        path: 'onboarding/FitnessLevel',
        route: 'fitness-level',
    },
    {
        path: 'onboarding/Goal',
        route: 'goal',
    },
    {
        path: 'onboarding/GoalConf',
        route: 'goal-conf',
    },
    {
        path: 'onboarding/LoaderAConfNumbers',
        route: 'loader-a-conf',
    },
    {
        path: 'onboarding/LoaderA',
        route: 'loader-a',
    },
    {
        path: 'onboarding/SignUpB',
        route: 'email-b',
    },
    {
        path: 'onboarding/TallA',
        route: 'height-a',
    },
    {
        path: 'onboarding/TargetBody',
        route: 'target-body-type',
    },
    {
        path: 'onboarding/TargetWeightA',
        route: 'target-weight-a',
    },
    {
        path: 'onboarding/TargetZones',
        route: 'target-zones',
    },
    {
        path: 'onboarding/TargetZonesConf',
        route: 'target-zones-conf',
    },
    {
        path: 'onboarding/WorkoutLocation',
        route: 'workout-location',
    },
    {
        path: 'onboarding/WorkoutLocationConf',
        route: 'workout-location-conf',
    },
    {
        path: 'onboarding/Motivation',
        route: 'motivation',
    },
    {
        path: 'onboarding/ObSocialProof',
        route: 'ob-social-proof',
    },
    {
        path: 'onboarding/ObSocialProofB',
        route: 'ob-social-proof-b',
    },
    {
        path: 'onboarding/PushUps',
        route: 'pushups',
    },
    {
        path: 'onboarding/LifeStyle',
        route: 'lifestyle',
    },
    {
        path: 'onboarding/WalkDaily',
        route: 'walk-daily',
    },
    {
        path: 'onboarding/ObTags',
        route: 'ob-tags',
    },
    {
        path: 'onboarding/ObOccasionA',
        route: 'ob-occasion-a',
    },
    {
        path: 'onboarding/ObOccasionResult',
        route: 'ob-occasion-result',
    },
    {
        path: 'onboarding/ObOccasionResultA',
        route: 'ob-occasion-result-a',
    },
    {
        path: 'onboarding/ObOccasionResultB',
        route: 'ob-occasion-result-b',
    },
    {
        path: 'onboarding/ObTargetBody',
        route: 'ob-target-body',
    },
    {
        path: 'onboarding/ObAttitude',
        route: 'ob-attitude',
    },
    {
        path: 'onboarding/ObAttitudeA',
        route: 'ob-attitude-a',
    },
    {
        path: 'onboarding/EmailD',
        route: 'email-d',
    },
    {
        path: 'onboarding/EmailAccountA',
        route: 'email-account-a',
    },
    {
        path: 'onboarding/ObRegularActivity',
        route: 'ob-regular-activity',
    },
    {
        path: 'onboarding/ObRegularActivityA',
        route: 'ob-regular-activity-a',
    },
    {
        path: 'onboarding/ProblemZonesB',
        route: 'problem-zones-b',
    },
    {
        path: 'onboarding/ObSocialProofConf',
        route: 'ob-social-proof-conf',
    },
    {
        path: 'onboarding/EmailDConf',
        route: 'email-d-conf',
    },
    {
        path: 'onboarding/EmailConsentConf',
        route: 'email-consent-conf',
    },
    {
        path: 'onboarding/ObFamiliarityConf',
        route: 'ob-familiarity-conf',
    },
    {
        path: 'onboarding/ObActualBodyTypeExt',
        route: 'ob-actual-body-type-ext',
    },
    {
        path: 'onboarding/ObTargetBodyTypeExt',
        route: 'ob-target-body-type-ext',
    },
    {
        path: 'onboarding/ObDiet',
        route: 'ob-diet',
    },
    {
        path: 'onboarding/ObRestrictiveDiet',
        route: 'ob-restrictive-diet',
    },
    {
        path: 'onboarding/EmailEConf',
        route: 'email-e-conf',
    },
    {
        path: 'onboarding/ObWorkoutIssues',
        route: 'ob-workout-issues',
    },
    {
        path: 'onboarding/ObWorkoutIssuesFeedback',
        route: 'ob-workout-issues-feedback',
    },
    {
        path: 'onboarding/ObCommitmentDayV1',
        route: 'ob-commitment-day-v1',
    },
    {
        path: 'onboarding/ObCommitmentDayV2',
        route: 'ob-commitment-day-v2',
    },
    {
        path: 'onboarding/ObCommitmentWeek',
        route: 'ob-commitment-week',
    },
    {
        path: 'onboarding/ObCurrentBodyTypeA',
        route: 'ob-current-body-type-a',
    },
    {
        path: 'onboarding/ObTargetBodyTypeA',
        route: 'ob-target-body-type-a',
    },
    {
        path: 'onboarding/ObUserStatements',
        route: 'ob-user-statements',
    },
    {
        path: 'onboarding/ObUserStatements1',
        route: 'ob-user-statements1',
    },
    {
        path: 'onboarding/ObUserStatements2',
        route: 'ob-user-statements2',
    },
    {
        path: 'onboarding/ObUserStatements3',
        route: 'ob-user-statements3',
    },
    {
        path: 'onboarding/ObUserStatements4',
        route: 'ob-user-statements4',
    },
    {
        path: 'onboarding/ObSportExperience',
        route: 'ob-sport-experience',
    },
    {
        path: 'onboarding/ObSportExperienceFeedback',
        route: 'ob-sport-experience-feedback',
    },
    {
        path: 'onboarding/ObMeasure',
        route: 'ob-measure',
    },
    {
        path: 'onboarding/ObPace',
        route: 'ob-pace',
    },
    {
        path: 'onboarding/ObVices',
        route: 'ob-vices',
    },
    {
        path: 'onboarding/ObBodyImage',
        route: 'ob-body-image',
    },
    {
        path: 'onboarding/ObHappyWeight',
        route: 'ob-happy-weight',
    },
    {
        path: 'onboarding/ObLimitations',
        route: 'ob-limitations',
    },
    {
        path: 'onboarding/ObIssues',
        route: 'ob-issues',
    },
    {
        path: 'onboarding/ObRisks',
        route: 'ob-risks',
    },
    {
        path: 'onboarding/EmailEConfB',
        route: 'email-e-conf-b',
    },
    {
        path: 'onboarding/EmailConsentConfB',
        route: 'email-consent-conf-b',
    },
    {
        path: 'onboarding/ObFamiliarityConfB',
        route: 'ob-familiarity-conf-b',
    },
    {
        path: 'onboarding/EmailEConfCheckbox',
        route: 'email-e-conf-checkbox',
    },
    {
        path: 'onboarding/ObChallenge',
        route: 'ob-challenge',
    },
    {
        path: 'onboarding/EmailConsentConfA',
        route: 'email-consent-conf-a',
    },
    {
        path: 'onboarding/ObUserStory',
        route: 'ob-user-story',
    },
    {
        path: 'onboarding/ObUserStoryA',
        route: 'ob-user-story-a',
    },
    {
        path: 'onboarding/ObUserStoryB',
        route: 'ob-user-story-b',
    },
    {
        path: 'onboarding/ObWorkoutLocation',
        route: 'ob-workout-location',
    },
    {
        path: 'onboarding/ObWorkoutFrequency',
        route: 'ob-workout-frequency',
    },
    {
        path: 'onboarding/ObWorkoutDuration',
        route: 'ob-workout-duration',
    },
    {
        path: 'onboarding/ObStrength',
        route: 'ob-strength',
    },
    {
        path: 'onboarding/ObStrengthGym',
        route: 'ob-strength-gym',
    },
    {
        path: 'onboarding/ObEndurance',
        route: 'ob-endurance',
    },
    {
        path: 'onboarding/ObConsistency',
        route: 'ob-consistency',
    },
    {
        path: 'onboarding/ObFitnessLevelFeedback',
        route: 'ob-fitness-level-feedback',
    },
    {
        path: 'onboarding/ObResult',
        route: 'ob-result',
    },

    // exercises
    {
        path: 'onboarding/exercises/ObBarbell',
        route: 'ob-barbell-exercise',
    },
    {
        path: 'onboarding/exercises/ObDumbbell',
        route: 'ob-dumbbell-exercise',
    },
    {
        path: 'onboarding/exercises/ObCrunch',
        route: 'ob-crunch-exercise',
    },
    {
        path: 'onboarding/exercises/ObPlank',
        route: 'ob-plank-exercise',
    },
    {
        path: 'onboarding/exercises/ObDumbbellShoulder',
        route: 'ob-dumbbell-shoulder-exercise',
    },
    {
        path: 'onboarding/exercises/ObPushUp',
        route: 'ob-pushup-exercise',
    },
    {
        path: 'onboarding/exercises/ObLimbRaise',
        route: 'ob-limb-raise-exercise',
    },
    {
        path: 'onboarding/exercises/ObWalkout',
        route: 'ob-walkout-exercise',
    },
    {
        path: 'onboarding/exercises/ObReverseLunges',
        route: 'ob-reverse-lunges-exercise',
    },
    {
        path: 'onboarding/ObFeedbackPages/ObFeedback',
        route: 'ob-feedback',
    },
    {
        path: 'onboarding/ObFeedbackPages/ObFeedbackA',
        route: 'ob-feedback-a',
    },
    {
        path: 'onboarding/ObFeedbackPages/ObFeedbackB',
        route: 'ob-feedback-b',
    },
    {
        path: 'onboarding/ObFeedbackPages/ObFeedbackC',
        route: 'ob-feedback-c',
    },
    {
        path: 'onboarding/ObFeedbackPages/ObFeedbackD',
        route: 'ob-feedback-d',
    },
    {
        path: 'onboarding/ObFeedbackPages/ObFeedbackE',
        route: 'ob-feedback-e',
    },

    // overweightFlow onboarding
    {
        path: 'onboarding/overweightFlow/PerfectWeightOver',
        route: 'perfect-weight-over',
    },
    {
        path: 'onboarding/overweightFlow/NutrHabbitFeel',
        route: 'nutr-habbit-feel',
    },
    {
        path: 'onboarding/overweightFlow/ActiveHabitSleep',
        route: 'active-habit-slip',
    },
    {
        path: 'onboarding/overweightFlow/NutrHabbitWater',
        route: 'nutr-habbit-water',
    },
    {
        path: 'onboarding/ObWorkoutType',
        route: 'ob-workout-type',
    },
    {
        path: 'onboarding/ObProblemZones',
        route: 'ob-problem-zones',
    },

    // Nutrition
    {
        path: 'onboarding/ObDailyDiet',
        route: 'ob-daily-diet',
    },
    {
        path: 'onboarding/ObDietSchedule',
        route: 'ob-diet-schedule',
    },
    {
        path: 'onboarding/ObCalories',
        route: 'ob-calories',
    },
    {
        path: 'onboarding/ObFeedbackNutrition',
        route: 'ob-feedback-nutrition',
    },
];
