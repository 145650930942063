import { SOLID_ERROR_CODE_NAMES } from 'constants/payment/payments';

import { Attempt, AttemptForScenario, PaymentMethod, Scenario } from '../types';

import { errorHandlers } from '../errorHandlers';

export const createErrorStrategy = (
    predefinedScenarios: Record<string, AttemptForScenario>,
    previousAttempt?: Attempt
): ((scenarioNumber: string) => Scenario) => {
    return (scenarioNumber: string): Scenario => {
        const attemptScenarios: AttemptForScenario = predefinedScenarios[scenarioNumber];
        const errorCode = previousAttempt?.response?.errorCode as keyof typeof SOLID_ERROR_CODE_NAMES;
        const prevPaymentMethod = previousAttempt?.paymentMethod as PaymentMethod;
        const errorScenarios = attemptScenarios?.errorsScenarios;

        if (errorScenarios) {
            const errorHandler = errorHandlers[errorCode];

            if (errorHandler) {
                const customScenario = errorHandler(prevPaymentMethod, errorCode, attemptScenarios);

                if (customScenario) {
                    return customScenario;
                }
            }
        }

        return attemptScenarios.default;
    };
};
