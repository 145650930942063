import React, { memo } from 'react';
import classNames from 'classnames/bind';

import styles from './ModalClose.module.css';

import Close from 'assets/img/icons/closeIcon.svg';

import { IModalClose } from 'types/pages/onboardingPages/commonTypes';

const cx = classNames.bind(styles);

function ModalClose({
    withLeftClose,
    onClick,
    dataLocator,
    withRightClose,
    withMarginTop,
    withBackground,
}: IModalClose) {
    const modalStyles = cx('closePopup', {
        withLeftClose,
        withRightClose,
        withMarginTop,
        withBackground,
    });

    return (
        <button className={modalStyles} onClick={onClick} data-locator={dataLocator}>
            <Close />
        </button>
    );
}

export default memo(ModalClose);
