import { PAYMENT_TYPES_CAPITALIZED } from 'constants/payment/payments';

import { PaymentMethod, Platform } from '../types';

export const getDefaultPaymentMethod = (platform: string): PaymentMethod => {
    switch (platform) {
        case Platform.IOS:
            return PAYMENT_TYPES_CAPITALIZED.APPLE_PAY;

        case Platform.ANDROID:
            return PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY;

        case Platform.DESKTOP:
            return PAYMENT_TYPES_CAPITALIZED.BANK_CARD;

        default:
            return PAYMENT_TYPES_CAPITALIZED.BANK_CARD;
    }
};
