import { PAYMENT_TYPES_CAPITALIZED } from 'constants/payment/payments';

import canPayWithApplePay from 'helpers/other/canPayWithApplePay';

import { Attempt, PaymentMethod, Scenario } from '../types';

export interface IPaymentData {
    userId: number;
    country: string;
    currency: string;
    payment_method: string;
}

export const calculateScenarioNumber = (prevPaymentAttempt: Attempt | null): string => {
    const scenarioNumber = prevPaymentAttempt ? Number(prevPaymentAttempt.scenarioNumber || 0) + 1 : 1;

    return scenarioNumber.toString();
};

export const getPrevPaymentAttempt = (
    prevPaymentAttempt: Attempt | null,
    paymentData: IPaymentData,
    errorCode: string
) => {
    const methodName = paymentData.payment_method;

    const paymentAttempt: Attempt = {
        scenarioNumber: calculateScenarioNumber(prevPaymentAttempt),
        paymentMethod: methodName,
        response: {
            errorCode: errorCode,
        },
    };

    return paymentAttempt;
};

export const getOnlyEnabledPaymentMethods = (
    scenario: Scenario,
    isApplePayAvailable: boolean,
    isGooglePayAvailable: boolean,
    enabledPaymentMethodsFromConfig: any
): PaymentMethod[] => {
    const { oneClickMethodsSequence } = scenario;
    const { APPLE_PAY, GOOGLE_PAY, PAYPAL } = PAYMENT_TYPES_CAPITALIZED;
    const { applePay, googlePay, payPal } = enabledPaymentMethodsFromConfig;
    const enabledPaymentMethods: PaymentMethod[] = [];

    for (const method of oneClickMethodsSequence) {
        if (
            (method === APPLE_PAY && isApplePayAvailable && applePay && canPayWithApplePay()) ||
            (method === GOOGLE_PAY && isGooglePayAvailable && googlePay) ||
            (method === PAYPAL && payPal)
        ) {
            enabledPaymentMethods.push(method);
        }
    }

    return enabledPaymentMethods;
};
