import { Main } from 'wikr-core-analytics';
import { useState } from 'react';

export const useCheckoutPolicy = () => {
    const isPolicyChecked = localStorage?.getItem('checkoutCheckbox');

    const [isChecked, setIsChecked] = useState(isPolicyChecked === 'checked');
    const [isError, setIsError] = useState(false);

    const onCheckboxClick = () => {
        if (!isChecked) {
            Main.customData('policy_consent__consent_provided__click');
            localStorage.setItem('checkoutCheckbox', 'checked');
            setIsError(false);
        } else {
            localStorage.removeItem('checkoutCheckbox');
        }

        setIsChecked(!isChecked);
    };

    const onOverlayClick = () => {
        if (!isChecked) setIsError(true);
    };

    return {
        isChecked,
        isError,
        onCheckboxClick,
        onOverlayClick,
    };
};
