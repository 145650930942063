import { TOKEN_KEY } from 'api/constants/common';

import { setToken } from './actions';

import { deleteCookie, setCookie } from 'helpers/cookie';

import { configureStore } from '../configureStore';

export const setTokenOperation = (token: string) => {
    const { store } = configureStore;

    setCookie(TOKEN_KEY, token);
    store.dispatch(setToken(token));
};

export const deleteTokenOperation = () => {
    const { store } = configureStore;

    deleteCookie(TOKEN_KEY);
    store.dispatch(setToken(null));
};
