export enum MotivationValues {
    improving_health = 'improving_health',
    boosting_immune = 'boosting_immune',
    looking_better = 'looking_better',
    building_strength_and_endurance = 'building_strength_and_endurance',
    boosting_libido = 'boosting_libido',
}

export const MOTIVATION_ANALYTICS_VALUES = {
    [MotivationValues.improving_health]: 'Improving health',
    [MotivationValues.boosting_immune]: 'Boosting immune system',
    [MotivationValues.looking_better]: 'Looking better',
    [MotivationValues.building_strength_and_endurance]: 'Building strength and endurance',
    [MotivationValues.boosting_libido]: 'Boosting libido',
};
