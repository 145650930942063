import { ERROR_LEVELS, SENTRY_AXIOS } from 'sentry-utils';

import config from 'config';

import { TOKEN_KEY } from 'api/constants/common';

import { deleteTokenOperation, setTokenOperation } from 'redux/User/operations';
import { getAuthTokenAccessor } from 'redux/User/accessors';

import { deleteConfig } from 'helpers/other/commonUtils';

import { ClientError, initApiClient } from 'welltech-web-api-client';
import sentry from '../Sentry/SentryInstance';

const apiClientInstaller = ({ extendResponse }: { extendResponse: boolean }) =>
    initApiClient({
        apiUrl: config.API_URL,
        apiVersion: config.API_VERSION,
        apiKey: config.API_KEY,
        authTokenKey: TOKEN_KEY,
        retries: 3,
        timeout: 40000,
        extendResponse,
        getAuthToken: () => {
            return getAuthTokenAccessor();
        },
        onUpdateToken: (token) => {
            setTokenOperation(token);
        },
        onAuthorisationError: (error: ClientError) => {
            const errorStatus = error?.response?.status;

            if (errorStatus === 403) {
                deleteTokenOperation();
                deleteConfig();

                location.replace('/');
            }
        },
        onErrorHandler: ({ extras, status, message, error }) => {
            const level = Number(status) >= 500 ? ERROR_LEVELS.CRITICAL : ERROR_LEVELS.ERROR;

            // @ts-ignore
            sentry.logError(new Error(message), SENTRY_AXIOS, level, { response: error?.response, ...extras }, [
                // @ts-ignore
                ['request_url', extras?.requestURL],
            ]);
        },
    });

export default apiClientInstaller;
