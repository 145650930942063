import motivation from 'assets/img/emoji/yawning-face.webp';
import other_icons from 'assets/img/emoji/setback.webp';
import time_icons from 'assets/img/emoji/no-time.webp';
import unclear_plan_icons from 'assets/img/emoji/no-plan.webp';
import motivation_icons from 'assets/img/emoji/no-motivation.webp';
import hard_workouts from 'assets/img/emoji/loudly-crying-face.webp';
import time from 'assets/img/emoji/hourglass-done.webp';
import bad_gym from 'assets/img/emoji/head-bandage-face.webp';
import hard_workouts_icons from 'assets/img/emoji/hard-workout.webp';
import other from 'assets/img/emoji/facepalming.webp';
import unclear_plan from 'assets/img/emoji/bad-memo.webp';
import bad_gym_icons from 'assets/img/emoji/bad-gym.webp';
import coaching_icons from 'assets/img/emoji/bad-coaching.webp';
import coaching from 'assets/img/emoji/bad-coaching-2.webp';

import { optionValue } from './types';

export enum WorkoutIssueValues {
    motivation = 'motivation',
    unclear_plan = 'unclear_plan',
    hard_workouts = 'hard_workouts',
    coaching = 'coaching',
    none_of_this = 'none_of_this',
    other = 'other',
    time = 'time',
    bad_gym = 'bad_gym',
}

export const iconsMap: optionValue = {
    [WorkoutIssueValues.motivation]: motivation_icons,
    [WorkoutIssueValues.unclear_plan]: unclear_plan_icons,
    [WorkoutIssueValues.hard_workouts]: hard_workouts_icons,
    [WorkoutIssueValues.coaching]: coaching_icons,
    [WorkoutIssueValues.bad_gym]: bad_gym_icons,
    [WorkoutIssueValues.other]: other_icons,
    [WorkoutIssueValues.time]: time_icons,
};

export const emojiMap: optionValue = {
    [WorkoutIssueValues.motivation]: motivation,
    [WorkoutIssueValues.coaching]: coaching,
    [WorkoutIssueValues.hard_workouts]: hard_workouts,
    [WorkoutIssueValues.time]: time,
    [WorkoutIssueValues.unclear_plan]: unclear_plan,
    [WorkoutIssueValues.other]: other,
    [WorkoutIssueValues.bad_gym]: bad_gym,
};
